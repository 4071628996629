<template>
    <RankingList
        class="cms-block"
        :elements="block.value.rows"
        :show-avatar="false" />
</template>

<script>
    import CmsBlockMixin from "./CmsBlockMixin";
    import RankingList from "@/components/lists/RankingList.vue";

    export default {
        name: "CmsGroupRanking",
        components: { RankingList },
        mixins: [CmsBlockMixin]
    };
</script>
