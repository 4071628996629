<template>
    <div class="cms-header-image">
        <img
            v-if="image && image.meta && renditions.large"
            class="cms-header-image__image cms-header-image__image--desktop"
            :src="renditions.large"
            :alt="image.title"
            loading="lazy" />
        <img
            v-if="image && image.meta && renditions.small"
            class="cms-header-image__image cms-header-image__image--mobile"
            :src="renditions.small"
            :alt="image.title"
            loading="lazy" />
    </div>
</template>

<script>
    export default {
        name: "CmsHeaderImage",
        props: {
            image: {
                type: {
                    title: {
                        type: String,
                        default: undefined
                    }
                }
            },
            renditions: {
                type: {
                    small: {
                        type: String,
                        default: ""
                    },
                    large: {
                        type: String,
                        default: ""
                    }
                }
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "~@/styles/variables";
    @import "~@/styles/mixins";

    .cms-header-image {
        &__image {
            width: 100%;
            object-fit: contain;

            &--mobile {
                @include desktop {
                    display: none;
                }
            }

            &--desktop {
                display: none;
                @include desktop {
                    display: block;
                }
            }
        }
    }
</style>
