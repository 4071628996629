import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/modules/Home/index.vue";
import i18n, { defaultLocale, mapLocale } from "@/locales";
import store from "@/store";
import { INTENTS } from "@/store/intent";
import { PHASES } from "@/constants";
import apiService from "@/services/api.service";
import Cookies from "js-cookie";
import PagePreview from "./modules/PagePreview.vue";

Vue.use(VueRouter);

const languagePrefix = "/:lang([a-zA-Z]{2})";

const Router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [
        {
            path: languagePrefix + "/checkpoint/:location",
            name: "checkpoint",
            component: () =>
                import(
                    /* webpackChunkName: "checkpoint" */ "@/modules/Checkpoint/index.vue"
                )
        },
        {
            path: languagePrefix + "/lapcounter/:id",
            name: "lapcounter",
            component: () =>
                import(
                    /* webpackChunkName: "checkpoint" */ "@/modules/Lapcounter/index.vue"
                )
        },
        {
            path: languagePrefix + "/statusboard",
            name: "statusboard",
            component: () =>
                import(
                    /* webpackChunkName: "checkpoint" */ "@/modules/Statusboard/index.vue"
                )
        },
        {
            path: languagePrefix + "/",
            name: "home",
            component: Home,
            meta: {
                title: "Homepage"
            }
        },
        {
            path: languagePrefix + "/login",
            name: "login",
            component: () =>
                import(
                    /* webpackChunkName: "login" */ "@/modules/Profile/Login.vue"
                ),
            meta: {
                title: "Login"
            }
        },
        {
            path: languagePrefix + "/auth",
            name: "auth",
            component: () =>
                import(
                    /* webpackChunkName: "login" */ "@/modules/Profile/Password.vue"
                ),
            meta: {
                title: "Login"
            }
        },
        {
            path: languagePrefix + "/register",
            alias: languagePrefix + "/dashboard/register",
            name: "register",
            component: () =>
                import(
                    /* webpackChunkName: "dashboard" */ "@/modules/Profile/Register.vue"
                ),
            meta: {
                title: "Register"
            }
        },
        {
            path: languagePrefix + "/qr_scanner",
            name: "qr_scanner",
            component: () =>
                import(
                    /* webpackChunkName: "checkpoint" */ "@/modules/QrScanner/index.vue"
                ),
            meta: {
                title: "QR Scanner"
            }
        },
        {
            path: languagePrefix + "/scan/:station",
            name: "scan",
            component: () =>
                import(
                    /* webpackChunkName: "dashboard" */ "@/modules/QrScanner/Scan.vue"
                ),
            meta: {
                title: "Scan"
            }
        },
        {
            path: languagePrefix + "/dashboard",
            name: "dashboard",
            component: () =>
                import(
                    /* webpackChunkName: "dashboard" */ "@/modules/Dashboard/index.vue"
                ),
            redirect: function (to) {
                const map = {
                    [this.INTENTS.SPONSOR]: "add_sponsoring",
                    [this.INTENTS.RUNNER]: "my_run"
                };
                let route = "my_run";
                if (
                    (this.store.state.auth.user.invoiceAddress.id ||
                        this.store.state.settings.phase === PHASES.AFTER_RUN) &&
                    !this.store.state.auth.user.runner.id
                )
                    route = "my_sponsoring";

                if (Object.keys(map).includes(this.store.state.intent.intent))
                    route = map[this.store.state.intent.intent];

                if (this.store.state.intent.runner)
                    route = map[this.INTENTS.SPONSOR];

                return { name: route, params: { lang: to.params.lang } };
            }.bind({ store, INTENTS }),
            meta: {
                title: "Dashboard"
            },
            children: [
                {
                    path: "run",
                    name: "my_run",
                    component: () =>
                        import(
                            /* webpackChunkName: "dashboard" */ "@/modules/Dashboard/MyRun.vue"
                        ),
                    beforeEnter: function (to, from, next) {
                        this.store.commit("intent/setIntent", "my_run");
                        next();
                    }.bind({ store }),
                    meta: {
                        title: "My run"
                    }
                },
                {
                    path: "sponsoring",
                    name: "my_sponsoring",
                    component: () =>
                        import(
                            /* webpackChunkName: "dashboard" */ "@/modules/Dashboard/MySponsoring.vue"
                        ),
                    beforeEnter: function (to, from, next) {
                        this.store.commit("intent/setIntent", "my_sponsoring");
                        next();
                    }.bind({ store }),
                    meta: {
                        title: "My sponsoring"
                    }
                },
                {
                    path: "register_runner",
                    name: "register_runner",
                    component: () =>
                        import(
                            /* webpackChunkName: "dashboard" */ "@/modules/Dashboard/RegisterRunner.vue"
                        ),
                    beforeEnter: function (to, from, next) {
                        this.store.commit("intent/resetIntent");
                        next();
                    }.bind({ store }),
                    meta: {
                        title: "My run"
                    }
                },
                {
                    path: "search_runner",
                    name: "search_runner",
                    component: () =>
                        import(
                            /* webpackChunkName: "dashboard" */ "@/modules/Dashboard/SearchRunner.vue"
                        ),
                    beforeEnter: function (to, from, next) {
                        this.store.commit("intent/resetIntent");
                        next();
                    }.bind({ store }),
                    meta: {
                        title: "My sponsoring"
                    }
                },
                {
                    path: "add_sponsoring",
                    name: "add_sponsoring",
                    component: () =>
                        import(
                            /* webpackChunkName: "dashboard" */ "@/modules/Dashboard/AddSponsoring.vue"
                        ),
                    beforeEnter: function (to, from, next) {
                        if (
                            !to.query.runner &&
                            !this.store.state.intent.runner
                        ) {
                            next({
                                name: "search_runner",
                                params: { lang: to.params.lang }
                            });
                            return;
                        }
                        this.store.commit("intent/resetIntent");
                        next();
                    }.bind({ store }),
                    meta: {
                        title: "My sponsoring"
                    }
                },
                {
                    path: "register_sponsor",
                    name: "register_sponsor",
                    component: () =>
                        import(
                            /* webpackChunkName: "dashboard" */ "@/modules/Dashboard/RegisterSponsor.vue"
                        ),
                    beforeEnter: function (to, from, next) {
                        this.store.commit("intent/resetIntent");
                        next();
                    }.bind({ store }),
                    meta: {
                        title: "My sponsoring"
                    }
                }
            ]
        },
        {
            path: languagePrefix + "/runner-link",
            name: "runner_link",
            component: () =>
                import(
                    /* webpackChunkName: "runner_link" */ "@/modules/RunnerLink/RunnerLink.vue"
                )
        },
        {
            path: languagePrefix + "/_page-preview",
            name: "page-preview",
            component: PagePreview,
            redirect: to => {
                if (Object.keys(to.query).includes("content_type")) {
                    const contentType = to.query.content_type;
                    if (contentType.includes("homepage"))
                        return {
                            name: "home-page-preview",
                            query: to.query,
                            params: to.params
                        };
                    else
                        return {
                            name: "content-page-preview",
                            query: to.query,
                            params: to.params
                        };
                }
                return { name: "Home", params: to.params };
            },
            children: [
                {
                    path: "home-page",
                    name: "home-page-preview",
                    meta: {
                        title: "Homepage"
                    },
                    component: Home
                },
                {
                    path: "content-page",
                    name: "content-page-preview",
                    component: () =>
                        import(
                            /* webpackChunkName: "preview" */ "@/modules/CmsPage/index.vue"
                        )
                }
            ]
        },
        {
            path: languagePrefix + "/:slug",
            name: "page",
            component: () =>
                import(
                    /* webpackChunkName: "cmspage" */ "@/modules/CmsPage/index.vue"
                )
        },
        {
            path: "/redirect/:redirect",
            name: "redirect",
            component: () =>
                import(
                    /* webpackChunkName: "redirect" */ "@/modules/Redirect/index.vue"
                )
        },
        {
            path: "*",
            name: "default",
            component: () =>
                import(
                    /* webpackChunkName: "redirect" */ "@/modules/Home/index.vue"
                )
        }
    ]
});

const sideEffects = function (to) {
    store.dispatch("navigation/close");

    if (to.query.intent)
        store.commit("intent/setIntent", to.query.intent.toUpperCase());

    if (to.query.runner) store.commit("intent/setRunner", to.query.runner);

    return true;
};

const restrictRoutes = function (to, _, next) {
    const restricted = [
        "dashboard",
        "register",
        "register_runner",
        "register_sponsor",
        "search_runner",
        "add_sponsoring",
        "my_run",
        "my_sponsoring",
        "qr_scanner"
    ];

    const restrictedRegister = [
        "dashboard",
        "register_runner",
        "register_sponsor",
        "search_runner",
        "add_sponsoring",
        "my_run",
        "my_sponsoring",
        "qr_scanner"
    ];

    const restrictedRunner = ["my_run", "qr_scanner"];

    const restrictedSponsor = [
        //'my_sponsoring',
    ];

    const guestOnly = ["login", "auth"];

    let user = store.state.auth.user;
    if (!user.id) {
        user = Cookies.get("user") ? JSON.parse(Cookies.get("user")) : {};
    }

    if (restricted.includes(to.name) && !store.getters["auth/isLoggedIn"])
        return next({ name: "login", params: { ...to.params } });

    if (restrictedRegister.includes(to.name))
        if (!user.firstname || !user.lastname)
            return next({ name: "register", params: { ...to.params } });

    if (restrictedRunner.includes(to.name) && !user.runner.id)
        return next({ name: "register_runner", params: { ...to.params } });

    if (restrictedSponsor.includes(to.name) && !user.invoiceAddress.id)
        return next({ name: "register_sponsor", params: { ...to.params } });

    if (guestOnly.includes(to.name) && store.getters["auth/isLoggedIn"])
        return next({ name: "dashboard", params: { ...to.params } });
    return true;
};

const localizeRoutes = function (to, from, next) {
    // This check observes if the url has a correct language parameter and sets it if missing
    if (!to.params.lang) {
        const lang =
            store.state.auth.language ||
            Cookies.get("language") ||
            i18n.locale ||
            defaultLocale;

        // Create a new Route object with the updated path
        const updatedRoute = {
            params: {
                ...to.params,
                lang
            },
            query: to.query,
            path: `${lang}${to.path}`
        };

        store.dispatch("auth/updateLanguage", { lang: to.params.lang });

        return next(updatedRoute);
    }

    // This check observes if the language parameter is valid and redirects if necessary
    if (to.params.lang !== mapLocale(to.params.lang)) {
        to.params.lang = mapLocale(to.params.lang);
        store.dispatch("auth/updateLanguage", { lang: to.params.lang });
        return next(to);
    }

    // This check observes language changes and sets the new language in the store and the i18n instance
    if (
        i18n.locale !== to.params.lang ||
        store.state.auth.language !== to.params.lang ||
        (from.params.lang && to.params.lang !== from.params.lang) ||
        apiService.getAcceptedLanguage() !== to.params.lang
    )
        store.dispatch("auth/updateLanguage", { lang: to.params.lang });

    return true;
};

const backOfficeRoutes = function (to, from, next) {
    const routes = ["checkpoint", "statusboard", "lapcounter"];

    if (routes.indexOf(to.name) >= 0) {
        store.dispatch("routing/setBackOfficeRoute", true);
        return next();
    }

    store.dispatch("routing/setBackOfficeRoute", false);
    return true;
};

Router.beforeEach((to, from, next) => {
    if (!backOfficeRoutes(to, from, next)) return;
    if (!sideEffects(to, from, next)) return;
    if (!localizeRoutes(to, from, next)) return;
    if (!restrictRoutes(to, from, next)) return;
    next();
});

Router.afterEach(to => {
    store.dispatch("seo/setTitle", i18n.t(to.meta.title));
    store.commit("auth/setLoading", false);
});

export default Router;
