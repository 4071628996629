<template>
    <div
        class="cms-block cms-block-image"
        :class="'cms-block-image--' + block.value.align.toLowerCase()">
        <a
            v-if="pageSlug"
            @click="navigateToPage">
            <img
                v-if="src"
                :src="src"
                :alt="title" />
        </a>
        <a
            v-else-if="linksToExternalPage"
            :href="block.value.external_link"
            target="_blank">
            <img
                v-if="src"
                :src="src"
                :alt="title" />
        </a>
        <img
            v-else-if="src"
            :src="src"
            :alt="title" />
    </div>
</template>

<script>
    import ApiService from "@/services/api.service";
    import CmsBlockMixin from "./CmsBlockMixin";

    export default {
        name: "CmsImage",
        mixins: [CmsBlockMixin],
        data() {
            return {
                src: undefined,
                title: undefined
            };
        },
        computed: {
            pageSlug() {
                return this.block.value.link_to_page_slug;
            },
            linksToExternalPage() {
                return this.block.value.external_link;
            }
        },
        watch: {
            block() {
                this.getImage();
            }
        },
        mounted() {
            this.getImage();
        },
        methods: {
            getImage() {
                if (!this.block.value.image) return;
                ApiService.get(`/images/${this.block.value.image}`).then(
                    response => {
                        this.src = response.data.meta.download_url;
                        this.title = response.data.title;
                    }
                );
            },
            navigateToPage() {
                if (!this.pageSlug) return;
                const lang =
                    this.block.value.link_to_page_locale ||
                    this.$route.params.lang;

                if (this.block.value.is_home_page) {
                    this.$router.push({
                        name: "home",
                        params: {
                            lang
                        }
                    });
                } else {
                    this.$router.push({
                        name: "page",
                        params: {
                            lang,
                            slug: this.pageSlug
                        }
                    });
                }
            }
        }
    };
</script>
<style scoped lang="scss">
    .cms-block-image {
        padding: 1.25rem 0;
        max-width: 100%;
        display: flex;

        &--left {
            justify-content: flex-start;
        }
        &--center {
            justify-content: center;
        }

        &--right {
            justify-content: flex-end;
        }

        img {
            max-width: 100%;
        }

        a {
            cursor: pointer;
        }
    }
</style>
