const actionsObj = {
    toggle: function ({ commit, state }) {
        commit("setOpen", !state.open);
    },
    open: function ({ commit }) {
        commit("setOpen", true);
    },
    close: function ({ commit }) {
        commit("setOpen", false);
    }
};

const stateObj = {
    open: false
};

const mutationsObj = {
    setOpen(state, open) {
        state.open = open;
    }
};

const gettersObj = {};

export default {
    namespaced: true,
    actions: actionsObj,
    state: stateObj,
    mutations: mutationsObj,
    getters: gettersObj
};
