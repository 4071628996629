<template>
    <section id="copyright">
        <span>
            {{ $t("Made with lots of") }}
            <i class="icon inline love" />
            {{ $t("and") }}
            <i class="icon inline coffee" />
            {{ $t("in Biel/Bienne by") }}
            <a
                href="https://smartfactory.ch/"
                target="_blank">
                {{ $t("Smartfactory GmbH.") }}
            </a>
        </span>
    </section>
</template>

<script>
    export default {
        name: "TheCopyright"
    };
</script>

<style scoped lang="scss">
    @import "~@/styles/variables";

    #copyright {
        box-sizing: border-box;
        width: 100%;
        padding: 1.25rem 0.625rem;
        text-align: center;
        font-size: 0.875rem;
        background-color: $color-primary;
        color: $color-white;

        a {
            text-decoration: none;
            color: $color-white;
        }
    }
</style>
