<template>
    <div
        v-if="$store.getters['auth/isLoggedIn']"
        class="cms-block">
        <form
            :id="id"
            @submit.prevent="submit">
            <fieldset class="fieldset">
                <p>
                    {{ $t("Want to invite someone as a runner?") }}<br />
                    {{
                        $t(
                            "Enter an e-mail message here and we will send an invitation:"
                        )
                    }}
                </p>
                <label :for="getId('email')">{{ $t("E-Mail address") }}</label>
                <input
                    :id="getId('email')"
                    v-model="form.email"
                    type="email"
                    required="required"
                    :placeholder="$t('email@example.com')"
                    :name="getId('email')" />
            </fieldset>
            <fieldset class="fieldset buttonset">
                <button>
                    {{ $t("Invite") }}
                </button>
            </fieldset>
            <CountdownTimer />
        </form>
    </div>
</template>

<script>
    import ApiService from "@/services/api.service";
    import CountdownTimer from "@/components/CountdownTimer.vue";
    import CmsBlockMixin from "./CmsBlockMixin";

    export default {
        name: "CmsAcquireRunner",
        components: {
            CountdownTimer
        },
        mixins: [CmsBlockMixin],
        data: function () {
            return {
                id: "acquire-runner",
                form: {
                    email: ""
                }
            };
        },
        computed: {
            messages() {
                return {
                    success: this.$t(
                        "Thank you! We send your friend an invitation via E-Mail."
                    ),
                    error: this.$t(
                        "We could not send an E-Mail your friend, please try again later."
                    ),
                    warnings: {
                        emailAlreadyExists: this.$t(
                            "We already sent an invitation to this E-Mail address."
                        )
                    }
                };
            }
        },
        methods: {
            getId(suffix = "") {
                return [this.id, suffix].join("-");
            },
            submit() {
                const data = {
                    email: this.form.email
                };

                this.$store.commit("auth/setLoading", true);

                ApiService.post("acquire/", data)
                    .then(this.submitSuccess)
                    .catch(this.submitError);
            },
            submitSuccess(response) {
                this.$store.commit(
                    "auth/setSuccessMessage",
                    this.messages.success
                );
                this.form.email = "";
                this.$store.commit("auth/setLoading", false);
                return response;
            },
            submitError(error) {
                try {
                    if (Object.keys(error.response.data).includes("email"))
                        this.$store.commit(
                            "auth/setWarningMessage",
                            this.messages.warnings.emailAlreadyExists
                        );
                } catch (e) {
                    this.$store.commit(
                        "auth/setErrorMessage",
                        this.messages.error
                    );
                }

                this.$store.commit("auth/setLoading", false);

                return error;
            }
        }
    };
</script>
