import { uuidv4 as uuid } from "../utils";

const stateObj = {
        messages: []
    },
    mutationsObj = {
        // Messages and Loading
        setMessages(state, messages = []) {
            for (let { type = null, message = null } of messages)
                if (typeof type === "string" && typeof message === "string")
                    state.messages.push({
                        id: uuid(),
                        timestamp: Date.now(),
                        type,
                        message
                    });
        },
        setMessage(state, { type, message }) {
            if (typeof type === "string" && typeof message === "string")
                state.messages.push({
                    id: uuid(),
                    timestamp: Date.now(),
                    type,
                    message
                });
        },
        removeMessage(state, uuid) {
            state.messages = state.messages.filter(
                message => message.id !== uuid
            );
        },
        popMessage(state) {
            state.messages.pop();
        },
        shiftMessage(state) {
            state.messages.shift();
        },
        setWarningMessage(state, message) {
            if (typeof message === "string")
                state.messages.push({
                    id: uuid(),
                    timestamp: Date.now(),
                    type: "warning",
                    message
                });
        },
        setErrorMessage(state, message) {
            if (typeof message === "string")
                state.messages.push({
                    id: uuid(),
                    timestamp: Date.now(),
                    type: "error",
                    message
                });
        },
        setSuccessMessage(state, message) {
            if (typeof message === "string")
                state.messages.push({
                    id: uuid(),
                    timestamp: Date.now(),
                    type: "success",
                    message
                });
        }
    };

export default {
    namespaced: true,
    state: stateObj,
    mutations: mutationsObj
};
