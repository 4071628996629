<template>
    <div
        v-if="!isLoggedIn"
        class="cms-block cms-block-login">
        <FormLogin :wide="true" />
    </div>
</template>

<script>
    import CmsBlockMixin from "./CmsBlockMixin";
    import FormLogin from "@/components/form/FormLogin";

    export default {
        name: "CmsLogin",
        components: { FormLogin },
        mixins: [CmsBlockMixin],
        computed: {
            isLoggedIn() {
                return this.$store.getters["auth/isLoggedIn"];
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "~@/styles/variables";
    @import "~@/styles/mixins";

    .cms-block-login {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }
</style>
