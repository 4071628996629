<template>
    <div class="cms-block cms-runner-list">
        <RunnerSearch
            v-if="!isLoading"
            :runners="runners" />
        <div
            v-else
            class="loading-message">
            {{ $t("Please wait, data is loading...") }}
        </div>
    </div>
</template>

<script>
    import ApiService from "@/services/api.service";
    import CmsBlockMixin from "./CmsBlockMixin";
    import RunnerSearch from "@/components/lists/RunnerSearch.vue";

    export default {
        name: "CmsRunnerList",
        components: {
            RunnerSearch
        },
        mixins: [CmsBlockMixin],
        data() {
            return {
                runners: [],
                isLoading: false
            };
        },
        mounted() {
            this.isLoading = true;
            ApiService.get("/runner_search")
                .then(response => (this.runners = response.data))
                .finally(() => (this.isLoading = false));
        }
    };
</script>

<style scoped>
    .loading-message {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1rem;
    }
</style>
