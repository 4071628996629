<template>
    <div class="timestamp">
        <template v-if="block">
            <span>{{ date }}</span>
            <span>{{ time }}</span>
        </template>
        <span v-else>
            {{ date }}
            {{ time }}
        </span>
    </div>
</template>

<script>
    import moment from "moment";

    export default {
        name: "Timestamp",
        props: {
            timestamp: {
                type: String,
                default: undefined
            },
            block: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            date() {
                return moment(this.timestamp).format("DD.MM.");
            },
            time() {
                return moment(this.timestamp).format("HH:mm");
            }
        }
    };
</script>
<style scoped lang="scss">
    .timestamp {
        display: flex;
        flex-direction: column;
    }
</style>
