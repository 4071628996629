import TextInput from "./inputs/TextInput.vue";
import NumberInput from "./inputs/NumberInput.vue";

export default {
    name: "FormMixin",
    components: { TextInput, NumberInput },
    props: {
        wide: {
            type: Boolean,
            default: false
        },
        block: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            errors: {},
            forceErrors: false
        };
    },
    computed: {
        classes() {
            return {
                "form": true,
                "form--wide": this.wide,
                "form--block": this.block
            };
        },
        valid() {
            this.validate();
            return (
                Object.values(this.errors).filter(value => !!value).length === 0
            );
        }
    },
    mounted() {
        this.validate();
    },
    methods: {
        validate() {},
        setError(field, error) {
            this.errors[field] = error || undefined;
        },
        deleteError(field) {
            this.setError(field);
        }
    }
};
