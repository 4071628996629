<template>
    <div class="cms-block cms-block-timer">
        <CountdownTimer />
    </div>
</template>

<script>
    import CmsBlockMixin from "./CmsBlockMixin";
    import CountdownTimer from "@/components/CountdownTimer.vue";

    export default {
        name: "CmsCountdownTimer",
        components: {
            CountdownTimer
        },
        mixins: [CmsBlockMixin]
    };
</script>
<style scoped lang="scss">
    .cms-block-timer {
        display: block;
        margin: 5rem 0;
    }
</style>
