<template>
    <div class="statistics">
        <h2 class="statistics__title">
            {{ $t("realtime counter") }}
        </h2>
        <div class="statistics__body">
            <div class="statistics__body__item">
                <h3>{{ $t("meters") }}</h3>
                <span> {{ metersWithLocale }}</span>
            </div>
            <div class="statistics__body__item">
                <h3>{{ $t("rounds") }}</h3>
                <span> {{ roundsWithLocale }} </span>
            </div>
            <div class="statistics__body__item">
                <h3>{{ $t("money collected") }}</h3>
                <span> CHF {{ money }} </span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Statistics",
        props: {
            meters: {
                type: Number,
                default: 0
            },
            rounds: {
                type: Number,
                default: 0
            },
            money: {
                type: String,
                default: ""
            }
        },
        computed: {
            metersWithLocale() {
                return this.meters.toLocaleString("de-CH").replace("’", "'");
            },
            roundsWithLocale() {
                return parseInt(this.rounds)
                    .toLocaleString("de-CH")
                    .replace("’", "'");
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "~@/styles/variables";
    @import "~@/styles/mixins";

    .statistics {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        @include desktop {
            gap: 1.5rem;
        }

        &__title {
            font-size: 1.5rem;
            color: $color-secondary;
        }

        &__body {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            gap: 1rem;

            @include desktop {
                gap: 1.5rem;
            }

            &__item {
                background-color: $color-white;
                padding: 0.625rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 1.5rem;

                h3 {
                    font-size: 1rem;
                    font-weight: normal;
                    text-transform: uppercase;
                }

                span {
                    font-size: 1.5rem;
                    font-weight: 700;
                }
            }

            &__item:last-child {
                grid-column: 1 / 3;
            }
        }
    }
</style>
