<template>
    <Counter
        :title="$t('Sponsoring')"
        :data="formattedCount" />
</template>

<script>
    import Counter from "@/components/Counter.vue";
    import ApiService from "@/services/api.service";

    export default {
        name: "SponsorCounter",
        components: {
            Counter
        },
        data() {
            return {
                count: null
            };
        },
        computed: {
            formattedCount() {
                if (this.count) {
                    const count = parseInt(this.count);
                    return count.toLocaleString("de-CH").replace("’", "'");
                }
                return this.count;
            }
        },
        mounted() {
            const url = "/statistics";
            ApiService.get(url).then(
                response =>
                    (this.count = response.data.sponsor_count.toString())
            );
        }
    };
</script>
