<template>
    <div>
        <div v-html="block.value" />
    </div>
</template>

<script>
    import CmsBlockMixin from "./CmsBlockMixin";

    export default {
        name: "CmsHtml",
        mixins: [CmsBlockMixin]
    };
</script>
