const actionsObj = {
    setBackOfficeRoute: function ({ commit }, backOfficeRoute) {
        commit("setBackOfficeRoute", backOfficeRoute);
    }
};

const stateObj = {
    backOfficeRoute: false
};

const mutationsObj = {
    setBackOfficeRoute(state, backOfficeRoute) {
        state.backOfficeRoute = backOfficeRoute;
    }
};

const gettersObj = {};

export default {
    namespaced: true,
    actions: actionsObj,
    state: stateObj,
    mutations: mutationsObj,
    getters: gettersObj
};
