<template>
    <div
        class="cms-block cms-block-heading"
        :class="classes">
        <h2
            v-if="block.value.upper"
            class="cms-block-heading__upper">
            {{ block.value.upper }}
        </h2>
        <h2
            v-if="block.value.lower"
            class="cms-block-heading__lower">
            {{ block.value.lower }}
        </h2>
    </div>
</template>

<script>
    import CmsBlockMixin from "./CmsBlockMixin";

    export default {
        name: "CmsHeading",
        mixins: [CmsBlockMixin],
        computed: {
            classes() {
                const classes = [];
                if (this.block.value.align)
                    classes.push(
                        "cms-block-heading--" +
                            this.block.value.align.toLowerCase()
                    );
                return classes;
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "~@/styles/variables";

    .cms-block-heading {
        text-align: center;

        h2 {
            margin-bottom: 1.5rem;
        }

        &__upper {
            color: $color-primary;
            text-transform: uppercase;
        }

        &__lower {
            color: $color-secondary;
        }

        &--left {
            text-align: left;
        }

        &--center {
            text-align: center;
        }

        &--right {
            text-align: right;
        }
    }
</style>
