<template>
    <countdown
        :time="time"
        :interval="1000"
        :transform="transform">
        <template slot-scope="props">
            <Counter
                :title="title"
                :data="getCounterData(props)" />
        </template>
    </countdown>
</template>

<script>
    import Vue from "vue";
    import Counter from "@/components/Counter.vue";
    import VueCountdown from "@chenfengyuan/vue-countdown";
    import { PHASES } from "@/constants";
    Vue.component(VueCountdown.name, VueCountdown);

    export default {
        name: "CountdownTimer",
        components: {
            Counter
        },
        data() {
            return {};
        },
        computed: {
            title() {
                switch (this.phase) {
                    case PHASES.DURING_RUN:
                        return this.$t("Run ends in");
                    case PHASES.AFTER_RUN:
                        return this.$t("Event over");
                    default:
                        return this.$t("It starts soon");
                }
            },
            time() {
                let now = new Date();
                let countdownDate = null;
                switch (this.phase) {
                    case PHASES.DURING_RUN:
                        countdownDate = this.settings.run_end;
                        break;
                    case PHASES.AFTER_RUN:
                        return;
                    default:
                        countdownDate = this.settings.run_start;
                }
                let countdownTime = Date.parse(countdownDate);
                return countdownTime - now;
            },
            phase() {
                return this.$store.state.settings.phase;
            },
            settings() {
                return this.$store.state.settings.settings;
            }
        },
        methods: {
            transform(props) {
                Object.entries(props).forEach(([key, value]) => {
                    // Adds leading zero
                    const digits = value < 10 ? `0${value}` : value;
                    props[key] = `${digits}`;
                });
                return props;
            },
            getCounterData(props) {
                return (
                    props.days +
                    ":" +
                    props.hours +
                    ":" +
                    props.minutes +
                    ":" +
                    props.seconds
                );
            }
        }
    };
</script>
