const stateObj = {
        settings: {
            registration_deadline: "2021-05-04T23:59:00+02:00",
            run_end: "2021-05-05T20:30:00+02:00",
            run_start: "2021-05-05T16:30:00+02:00"
        },
        phase: "REGISTRATION"
    },
    mutationsObj = {
        // Messages and Loading
        setSettings(state, settings = {}) {
            state.settings = settings;
        },
        setPhase(state, phase) {
            state.phase = phase;
        }
    };

export default {
    namespaced: true,
    state: stateObj,
    mutations: mutationsObj
};
