<template>
    <nav
        id="navigation-mobile"
        class="navigation-mobile">
        <button
            class="navigation-mobile__toggle"
            @click="toggle">
            <img
                src="@/assets/icons/burger.svg"
                alt="burger menu" />
        </button>
        <My442 />
        <div class="navigation-mobile__drawer">
            <div
                ref="drawer"
                class="navigation-mobile__drawer__wrapper">
                <div class="navigation-mobile__drawer__wrapper__header">
                    <button
                        class="navigation-mobile__toggle"
                        @click="toggle">
                        <img
                            src="@/assets/icons/close.svg"
                            alt="close menu" />
                    </button>
                    <My442 />
                </div>
                <div class="navigation-mobile__drawer__wrapper__body">
                    <ul class="navigation-mobile__drawer__list">
                        <DropdownNavigationItem
                            v-for="item in menuItems"
                            :key="item.meta.slug"
                            :item="item" />
                    </ul>
                    <div
                        class="navigation-mobile__drawer__wrapper__language-switcher">
                        <TheLanguageSwitcher />
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
    import My442 from "@/components/My442.vue";
    import DropdownNavigationItem from "@/components/layout/header/DropdownNavigationItem.vue";
    import TheLanguageSwitcher from "./TheLanguageSwitcher.vue";
    import NavigationMixin from "./NavigationMixin";

    export default {
        name: "TheMobileNavigation",
        components: {
            My442,
            DropdownNavigationItem,
            TheLanguageSwitcher
        },
        mixins: [NavigationMixin],
        methods: {
            toggle() {
                this.$store.dispatch("navigation/toggle");
            },
            close() {
                this.$store.dispatch("navigation/close");
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "~@/styles/variables";
    @import "~@/styles/mixins";

    .navigation-mobile {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.5rem 1rem;
        border-bottom: 1px solid $color-grey;

        @include desktop {
            display: none;
        }

        &__toggle {
            border: 0;
            padding: 0;
            height: 39px;
            width: 39px;
            background-color: transparent;

            img {
                width: 100%;
                height: 100%;
            }
        }

        &::before {
            content: "";
            position: fixed;
            position: fixed;
            width: 100vw;
            height: 100vh;
            z-index: 999;
            background: $color-grey-light;
            left: -100%;
            top: 0;
            box-sizing: border-box;
        }

        &__drawer {
            position: fixed;
            width: 100vw;
            height: 100svh;
            z-index: 1000;
            background: $color-grey-light;
            left: -100%;
            top: 0;
            overflow-y: auto;
            box-sizing: border-box;

            &__wrapper {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 100%;

                &__header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 1.5rem 1rem;
                    border-bottom: 1px solid $color-grey;
                    background: $color-white;
                }

                &__body {
                    flex-grow: 1;
                    justify-content: space-between;
                    padding: 2.5rem 1rem;
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;

                    ul {
                        padding: 0;
                        margin: 0;
                        display: flex;
                        flex-direction: column;
                        gap: 1.5rem;
                    }
                }

                &__language-switcher {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
</style>
