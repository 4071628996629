<template>
    <div class="teasers">
        <CmsTeaser
            v-for="(teaser, index) of teasers"
            :key="index"
            :block="teaser" />
    </div>
</template>

<script>
    import CmsTeaser from "@/components/cms/blocks/CmsTeaser.vue";

    export default {
        name: "Teasers",
        components: {
            CmsTeaser
        },
        computed: {
            teasers() {
                return [
                    {
                        value: {
                            src: require("@/assets/icons/icon-runner-2023.png"),
                            title: this.$t("Who can participate?"),
                            text: this.$t(
                                "Everyone who feels fit enough to complete the course one or more times; running, hiking, walking - time is not an issue. The descent is done by train for relaxation."
                            )
                        }
                    },
                    {
                        value: {
                            src: require("@/assets/icons/icon-sponsor-2023.png"),
                            title: this.$t("Sponsoring"),
                            text: this.$t(
                                "Each runner acquires her or his own sponsors. The sponsors can set an amount per vertical meter, per course or a flat rate for participation."
                            )
                        }
                    },
                    {
                        value: {
                            src: require("@/assets/icons/icon-donation-2023.png"),
                            title: this.$t("In favour of"),
                            text: this.$t(
                                "The aim of the run is to generate money to fight rare diseases and to support those affected and their families. Rare diseases are diseases that occur only sporadically; in Switzerland, for example, there are often fewer than 20 people affected. In the case of such diseases there is usually a lack of specific medication for treatment and the insurance question is often unresolved, which often leads to personal and financial hardship for the affected and their relatives."
                            )
                        }
                    }
                ];
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "~@/styles/variables";

    .teasers {
        margin-bottom: 122px;
    }
</style>
