<template>
    <div
        class="list"
        :class="{ 'list--full-width': fullWidth }">
        <ul>
            <template v-for="(element, index) in elements">
                <li class="list__item">
                    <slot
                        name="item"
                        v-bind="{ ...element, index }" />
                </li>
                <hr v-if="showSeparator(index)" />
            </template>
            <hr
                v-if="showSeparators && $slots.additional"
                class="list__additional-hr" />
            <li
                v-if="$slots.additional"
                class="list__item list__item--additional">
                <slot name="additional" />
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "List",
        props: {
            elements: {
                type: Array,
                default: () => []
            },
            showSeparators: {
                type: Boolean,
                default: true
            },
            fullWidth: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            showSeparator(index) {
                return (
                    this.showSeparators &&
                    (!this.$slots.additional ||
                        index + 1 < this.elements.length)
                );
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "~@/styles/variables";
    @import "~@/styles/mixins";

    .list {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;

        ul {
            width: 100%;
            list-style: none;
            padding: 0;
            margin: 0;

            @include desktop {
                max-width: 588px;
            }
        }

        &--full-width ul {
            @include desktop {
                max-width: none;
            }
        }

        hr {
            border: none;
            border-top: 1px solid $color-black;
            width: 100%;
            margin: 0;

            &.list__additional-hr {
                border-top: 2px solid $color-secondary;
            }
        }

        &__item {
            display: flex;
            align-items: center;
            padding: 1rem 0;

            &--additional {
                display: flex;
                justify-content: space-between;
            }
        }
    }
</style>
<style lang="scss">
    @import "~@/styles/variables";

    .list {
        &__right {
            color: $color-secondary;
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;
        }
    }
</style>
