<template>
    <div class="cms-block cms-block-gallery">
        <div ref="masonry">
            <CoolLightBox
                :items="largeImages"
                :index="index"
                :gallery="false"
                @close="index = null" />
            <img
                v-for="(url, imageIndex) in thumbnails"
                ref="images"
                :key="url"
                class="grid-item"
                :src="url"
                alt="442 Gallery Image"
                @click="index = imageIndex" />
        </div>
    </div>
</template>

<script>
    import Masonry from "masonry-layout";
    import CoolLightBox from "vue-cool-lightbox";
    import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
    import CmsBlockMixin from "./CmsBlockMixin";

    export default {
        name: "CmsGallery",
        components: {
            CoolLightBox
        },
        mixins: [CmsBlockMixin],
        data() {
            return {
                index: null
            };
        },
        computed: {
            thumbnails() {
                return this.block.value.images[0];
            },
            largeImages() {
                return this.block.value.images[1];
            }
        },
        mounted() {
            const masonry = new Masonry(this.$refs.masonry, {
                itemSelector: ".grid-item",
                columWidth: 100,
                fitWidth: true,
                transitionDuration: ".2s"
            });

            // layout the grid after every image has loaded
            Promise.all(
                this.$refs.images.map(
                    image => new Promise(resolve => (image.onload = resolve))
                )
            ).then(() => window.setTimeout(() => masonry.layout(), 100));
        }
    };
</script>

<style scoped lang="scss">
    @import "~@/styles/mixins";

    .cms-block-gallery {
        display: flex;
        justify-content: center;
    }

    .grid-item {
        margin: 0 auto;
        padding: 0.3125rem;
        width: 200px;
        cursor: pointer;

        @include desktop {
            width: 300px;
        }
    }
</style>
