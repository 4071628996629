<template>
    <figure
        class="avatar"
        :class="'avatar--' + size">
        <img
            v-if="runner && runner.avatar"
            class="avatar__image"
            :src="runner.avatar"
            :alt="$t('User avatar')" />
        <img
            v-else
            class="avatar__image"
            :src="randomAvatar"
            :alt="$t('User avatar')" />
    </figure>
</template>

<script>
    export default {
        name: "Avatar",
        props: {
            runner: Object,
            size: {
                type: String,
                default: "md"
            }
        },
        computed: {
            randomAvatar() {
                // Uses the pixel-art type of the dicebear api
                const firstName =
                    this.runner.runner_firstname || this.runner.first_name;
                const lastName =
                    this.runner.runner_lastname || this.runner.last_name;
                return `https://api.dicebear.com/8.x/pixel-art/svg?scale=70&seed=${firstName}${lastName}${this.runner.id}`;
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "~@/styles/variables";

    .avatar {
        height: 65px;
        width: 65px;
        min-width: 65px;
        min-height: 65px;

        &__image {
            height: 100%;
            width: 100%;
            background-color: $color-grey;
            border-radius: 50%;
        }

        &--sm {
            height: 40px;
            width: 40px;
            min-width: 40px;
            min-height: 40px;
        }
    }
</style>
