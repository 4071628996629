const actionsObj = {};

const stateObj = {
    intent: null,
    runner: null,
    runnerListFallback: false
};

export const INTENTS = {
    SPONSOR: "SPONSOR",
    RUNNER: "RUNNER"
};

const mutationsObj = {
    setSponsorIntent(state) {
        state.intent = INTENTS.SPONSOR;
    },
    setRunnerIntent(state) {
        state.intent = INTENTS.RUNNER;
    },
    setIntent(state, intent) {
        if (Object.keys(INTENTS).includes(intent)) state.intent = intent;
    },
    resetIntent(state) {
        state.intent = null;
    },
    setRunner(state, id) {
        state.runner = id;
    },
    resetRunner(state) {
        state.runner = null;
    },
    setRunnerListFallback(state, fallback) {
        state.runnerListFallback = fallback;
    }
};

const gettersObj = {};

export default {
    namespaced: true,
    actions: actionsObj,
    state: stateObj,
    mutations: mutationsObj,
    getters: gettersObj
};
