<template>
    <router-link
        active-class="active"
        :to="{ name: 'dashboard', params: { lang: $i18n.locale } }"
        class="button button--secondary">
        {{ $t("My +442") }}
    </router-link>
</template>

<script>
    export default {
        name: "My442"
    };
</script>
