<template>
    <nav
        id="navigation-desktop"
        class="navigation-desktop">
        <div class="navigation-desktop__language-switcher">
            <TheLanguageSwitcher />
        </div>
        <ul class="navigation-desktop__list">
            <DropdownNavigationItem
                v-for="item in menuItems"
                :key="item.meta.slug"
                :item="item"
                :overlay="true" />
            <li>
                <My442 />
            </li>
        </ul>
    </nav>
</template>

<script>
    import My442 from "@/components/My442.vue";
    import DropdownNavigationItem from "@/components/layout/header/DropdownNavigationItem.vue";
    import TheLanguageSwitcher from "./TheLanguageSwitcher.vue";
    import NavigationMixin from "./NavigationMixin";

    export default {
        name: "TheDesktopNavigation",
        components: {
            My442,
            DropdownNavigationItem,
            TheLanguageSwitcher
        },
        mixins: [NavigationMixin]
    };
</script>

<style scoped lang="scss">
    @import "~@/styles/variables";
    @import "~@/styles/mixins";

    .navigation-desktop {
        display: none;
        width: 100%;
        max-width: $content-width;
        flex-direction: column;
        gap: 1.5rem;
        padding: 0 2.5rem;
        margin-bottom: 0.625rem;
        box-sizing: border-box;

        @include desktop {
            display: flex;
        }

        &__language-switcher {
            align-self: flex-end;
        }

        &__list {
            display: grid;
            grid-auto-flow: column;
            list-style: none;
            padding: 0;
            margin: 0;
            gap: 1.5rem;

            li {
                display: flex;
                flex-grow: 1;
            }

            li:last-child {
                justify-self: end;
            }
        }
    }
</style>
