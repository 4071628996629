import CmsAcquireRunner from "./CmsAcquireRunner.vue";
import CmsButton from "./CmsButton.vue";
import CmsCards from "./CmsCards.vue";
import CmsCountdownTimer from "./CmsCountdownTimer.vue";
import CmsCounters from "./CmsCounters.vue";
import CmsGallery from "./CmsGallery.vue";
import CmsGroupRanking from "./CmsGroupRanking.vue";
import CmsGroupRunnerList from "./CmsGroupRunnerList.vue";
import CmsHeading from "./CmsHeading.vue";
import CmsHtml from "./CmsHtml.vue";
import CmsImage from "./CmsImage.vue";
import CmsLogin from "./CmsLogin.vue";
import CmsRunnerList from "./CmsRunnerList.vue";
import CmsRunnerRanking from "./CmsRunnerRanking.vue";
import CmsSpace from "./CmsSpace.vue";
import CmsSponsors from "./CmsSponsors.vue";
import CmsTeaser from "./CmsTeaser.vue";
import CmsText from "./CmsText.vue";
import CmsVideo from "./CmsVideo.vue";

export function getBlockComponent(block) {
    switch (block.type) {
        case "runner_invitation":
            return "CmsAcquireRunner";
        case "button":
            return "CmsButton";
        case "cards":
            return "CmsCards";
        case "time":
            return "CmsCountdownTimer";
        case "counters":
            return "CmsCounters";
        case "image_gallery":
            return "CmsGallery";
        case "ranking_group":
            return "CmsGroupRanking";
        case "groupRunnerList":
            return "CmsGroupRunnerList";
        case "heading":
            return "CmsHeading";
        case "html":
            return "CmsHtml";
        case "image":
            return "CmsImage";
        case "login":
            return "CmsLogin";
        case "runnerList":
            return "CmsRunnerList";
        case "ranking_runner":
            return "CmsRunnerRanking";
        case "space":
            return "CmsSpace";
        case "event_sponsor":
            return "CmsSponsors";
        case "teaser":
            return "CmsTeaser";
        case "text":
            return "CmsText";
        case "video":
            return "CmsVideo";
    }
}

export default {
    CmsAcquireRunner,
    CmsButton,
    CmsCards,
    CmsCountdownTimer,
    CmsCounters,
    CmsGallery,
    CmsGroupRanking,
    CmsGroupRunnerList,
    CmsHeading,
    CmsHtml,
    CmsImage,
    CmsLogin,
    CmsRunnerList,
    CmsRunnerRanking,
    CmsSpace,
    CmsSponsors,
    CmsTeaser,
    CmsText,
    CmsVideo
};
