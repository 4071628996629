<template>
    <Counter
        :title="$t('Runners')"
        :data="formattedCount" />
</template>

<script>
    import Counter from "@/components/Counter.vue";
    import ApiService from "@/services/api.service";

    export default {
        name: "RunnerCounter",
        components: {
            Counter
        },
        data() {
            return {
                count: null
            };
        },
        computed: {
            formattedCount() {
                if (this.count) return this.count.toLocaleString("de-CH");
                return this.count;
            }
        },
        mounted() {
            const url = "/statistics";
            ApiService.get(url).then(
                response => (this.count = response.data.runner_count.toString())
            );
        }
    };
</script>
