<template>
    <div class="ticker">
        <h2 class="ticker__title">
            {{ $t("ticker") }}
        </h2>
        <div
            v-if="laps.length"
            class="ticker__runner-list">
            <List :elements="laps">
                <template v-slot:item="runner">
                    <ListItemRunnerTicker :runner="runner" />
                </template>
            </List>
        </div>
        <div
            v-else
            class="ticker__placeholder">
            <div class="ticker__placeholder__text">
                {{
                    $t(
                        "As soon as the first laps have been run, they will be shown here"
                    )
                }}
            </div>
        </div>
    </div>
</template>

<script>
    import ApiService from "@/services/api.service";
    import List from "@/components/lists/List.vue";
    import ListItemRunnerTicker from "./item/ListItemRunnerTicker.vue";

    export default {
        name: "RunnerTicker",
        components: { List, ListItemRunnerTicker },
        data() {
            return {
                laps: []
            };
        },
        mounted() {
            this.getLastFiveRun();
        },
        methods: {
            getLastFiveRun() {
                ApiService.get("/laps/lastfive").then(
                    response => (this.laps = response.data)
                );
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "~@/styles/variables";
    @import "~@/styles/mixins";

    .ticker {
        padding: 2rem 1rem;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        @include desktop {
            padding: 2rem 0;
        }

        &__title {
            font-size: 1.5rem;
            line-height: 2rem;
            font-weight: 700;
            color: $color-secondary;
        }
    }
</style>
