<template>
    <li
        class="dropdown-navigation-item"
        :class="{
            'dropdown-navigation-item--open': open,
            'dropdown-navigation-item--overlay': overlay,
            'dropdown-navigation-item--active': parentActive
        }"
        @click="toggleDorpdown('click')"
        @mouseenter="openDropdown('enter')"
        @mouseleave="closeDropdown('leave')">
        <div class="dropdown-navigation-item__parent">
            <span>{{ item.title }}</span>
            <img src="@/assets/icons/chevron-right.svg" />
        </div>
        <div
            ref="accordion"
            class="dropdown-navigation-item__wrapper">
            <ul class="dropdown-navigation-item__wrapper__children">
                <li
                    v-for="(child, index) in item.children"
                    :key="index">
                    <NavigationItem :item="child" />
                </li>
            </ul>
        </div>
    </li>
</template>

<script>
    import NavigationItem from "./NavigationItem.vue";

    export default {
        name: "DropdownNavigationItem",
        components: {
            NavigationItem
        },
        props: {
            item: Object,
            overlay: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                open: false,
                lastEvent: undefined
            };
        },
        computed: {
            parentActive() {
                // parent is active when one (or more) of the children is active
                // a child is active when the route is 'page' and the slug param matches
                // the child slug
                const slug = this.$route.params.slug;
                const isPage = this.$route.name === "page";
                return (
                    isPage &&
                    this.item.children.some(child => child.slug === slug)
                );
            }
        },
        methods: {
            closeDropdown(event) {
                this.lastEvent = event;
                this.open = false;
                this.$refs.accordion.style.maxHeight = "0";
            },
            openDropdown(event) {
                this.lastEvent = event;
                this.open = true;
                this.$refs.accordion.style.maxHeight =
                    this.$refs.accordion.scrollHeight + "px";
            },
            toggleDorpdown(event) {
                if (this.lastEvent == "enter") return (this.lastEvent = event);
                this.open
                    ? this.closeDropdown(event)
                    : this.openDropdown(event);
            },
            to(item) {
                return {
                    name: "page",
                    params: { slug: item.slug, lang: this.$i18n.locale }
                };
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "~@/styles/variables";

    .dropdown-navigation-item {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        list-style: none;
        position: relative;
        line-height: 1.5rem;

        &__parent {
            border-bottom: 1px solid $color-grey;
            transition:
                border-bottom-color 0.2s ease,
                border-bottom-width 0.2s ease;
            padding: 0.625rem;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 1rem;
            text-transform: uppercase;

            img {
                transition: transform 0.2s ease;
            }
        }

        &__wrapper {
            overflow: hidden;
            max-height: 0;
            transition: max-height 0.2s ease;

            &__children {
                list-style: none;
                padding: 0;
                display: flex;
                flex-direction: column;
                gap: 0.5rem;

                li {
                    display: flex;
                }
            }
        }

        &--overlay &__parent {
            border: 0;
        }

        &--overlay &__wrapper {
            margin-top: 0.625rem;
            background-color: $color-white;
            position: absolute;
            z-index: 1000;
            top: 100%;
            width: 100%;
            box-shadow: 0px 4px 11.899999618530273px 0px #00000014;
        }

        &--overlay &__wrapper &__wrapper__children {
            padding: 0.5rem 0;
            border-top: 4px solid $color-secondary-light;
            gap: 0;
        }

        &--active &__parent,
        &__parent:hover {
            color: $color-primary;
        }

        &--active &__parent {
            font-weight: 700;
        }

        &--open &__parent {
            border-bottom-color: $color-secondary-light;
            border-bottom-width: 2px;
        }

        &--open &__parent img {
            transform: rotate(90deg);
        }
    }
</style>
