<template>
    <label :class="classes">
        <input
            :id="id || name"
            v-model="innerValue"
            type="number"
            :name="name || id"
            :step="step"
            @blur="handleBlur" />
        <span :class="placeholderClasses">{{ placeholder }}</span>
        <span
            v-if="error && (showError || forceError)"
            :class="errorClasses">
            <i class="icon error inline" />
            {{ error }}
        </span>
    </label>
</template>
<script>
    import InputMixin from "./InputMixin";

    export default {
        name: "NumberInput",
        mixins: [InputMixin],
        props: {
            step: {
                type: Number,
                default: 1
            },
            value: {
                type: Number,
                default: undefined
            }
        },
        computed: {
            innerValue: {
                get() {
                    return this.value;
                },
                set(value) {
                    value = parseFloat(value);
                    if (isNaN(value)) value = undefined;
                    this.$emit("input", value);
                }
            },
            filled() {
                return this.innerValue !== undefined;
            }
        }
    };
</script>
