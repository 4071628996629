<template>
    <header class="header">
        <TheMobileNavigation />
        <figure class="header__logo">
            <router-link
                :to="{
                    name: 'home',
                    params: { lang: $store.state.auth.language }
                }">
                <img
                    class="header__logo__image"
                    src="@/assets/logo-2024.png"
                    alt="logo" />
            </router-link>
        </figure>
        <TheDesktopNavigation />
        <section class="header__banner">
            <Banner
                :text="bannerText"
                :url="bannerUrl"
                color="#fff"
                background-color="#669998"
                font-size="18"
                font-style="italic"
                font-weight="900"
                text-transform="uppercase" />
        </section>
    </header>
</template>

<script>
    import TheDesktopNavigation from "./TheDesktopNavigation.vue";
    import TheMobileNavigation from "./TheMobileNavigation.vue";
    import Banner from "@/components/Banner";
    import ApiService from "@/services/api.service";

    export default {
        name: "TheHeader",
        components: {
            Banner,
            TheMobileNavigation,
            TheDesktopNavigation
        },
        data: function () {
            return {
                bannerText: "",
                bannerUrl: null
            };
        },
        watch: {
            $route() {
                this.getBannerText();
            }
        },
        mounted() {
            this.getBannerText();
        },
        methods: {
            getBannerText: function () {
                const url =
                    "/pages/?fields=message_of_the_day,message_of_the_day_url&format=json&type=cms.HomePage";
                ApiService.get(url, true)
                    .then(response => {
                        const items = response.data.items;
                        if (!items || !items.length)
                            throw new Error(
                                "The requested slug is not a cms page"
                            );
                        const page = items.shift();
                        this.bannerText = page.message_of_the_day;
                        this.bannerUrl = page.message_of_the_day_url;
                    })
                    .catch();
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "~@/styles/variables";
    @import "~@/styles/mixins";

    .header {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &__logo {
            width: 100%;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            padding: 5rem 1rem;

            @include desktop {
                padding: 10rem 1rem 5rem 1rem;
            }

            &__image {
                width: 100%;
                max-width: 358px;

                @include desktop {
                    max-width: 588px;
                }
            }
        }

        &__banner {
            display: flex;
            width: 100%;
            height: 100%;
        }
    }
</style>
