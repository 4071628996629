<template>
    <div class="status-board">
        <div class="status-board__left">
            <Statistics
                v-if="!isBeforeRun"
                :rounds="statistics.finishedLaps"
                :meters="statistics.meters"
                :money="statistics.totalMoney" />
            <div
                class="status-board__left__content"
                v-html="item.statusboard_left_content" />
        </div>
        <RunnerTicker v-if="isDuringRun" />
        <template v-else>
            <div class="status-board__right">
                <img
                    v-if="item.statusboard_right_background_image"
                    :src="item.statusboard_right_background_image.url" />
                <img
                    v-else
                    src="../../../assets/images/Image12.webp" />
                <div class="status-board__right__content">
                    <h2 v-if="item.statusboard_right_title">
                        {{ item.statusboard_right_title }}
                    </h2>
                    <div
                        v-if="item.statusboard_right_content"
                        v-html="item.statusboard_right_content" />
                    <div
                        v-if="showButtons"
                        class="status-board__right__buttons">
                        <button
                            v-if="item.statusboard_right_button_label_1"
                            class="button button--primary-white"
                            @click="
                                navigateToPage(
                                    item.statusboard_right_button_link_1
                                )
                            ">
                            {{ item.statusboard_right_button_label_1 }}
                        </button>
                        <button
                            v-if="item.statusboard_right_button_label_2"
                            class="button button--primary-white"
                            @click="
                                navigateToPage(
                                    item.statusboard_right_button_link_2
                                )
                            ">
                            {{ item.statusboard_right_button_label_2 }}
                        </button>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import Statistics from "@/components/Statistics.vue";
    import RunnerTicker from "@/components/lists/RunnerTicker.vue";
    import { PHASES } from "../../../constants";

    export default {
        name: "Statusboard",
        components: {
            Statistics,
            RunnerTicker
        },
        props: {
            item: Object,
            statistics: Object
        },
        computed: {
            isBeforeRun() {
                return this.$store.state.settings.phase === PHASES.BEFORE_RUN;
            },
            isDuringRun() {
                return this.$store.state.settings.phase === PHASES.DURING_RUN;
            },
            showButtons() {
                return (
                    this.item.statusboard_right_button_label_1 ||
                    this.item.statusboard_right_button_label_2
                );
            }
        },
        methods: {
            navigateToPage(page) {
                if (!page) return;
                const lang = page.locale || this.$route.params.lang;

                if (page.is_home_page) {
                    this.$router.push({
                        name: "home",
                        params: {
                            lang
                        }
                    });
                } else {
                    this.$router.push({
                        name: "page",
                        params: {
                            lang,
                            slug: page.slug
                        }
                    });
                }
            }
        }
    };
</script>

<style scoped lang="scss">
    @use "sass:color";
    @import "~@/styles/variables";
    @import "~@/styles/mixins";

    .status-board {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1.5rem;
        padding: 0;

        @include desktop {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr;
            padding: 0 2.5rem 2.5rem 2.5rem;
        }

        &__left {
            padding: 2rem 1rem 1.5rem 1rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            background-color: $color-grey-light;

            @include desktop {
                padding: 2rem 1.5rem 1.5rem 1.5rem;
                gap: 1.5rem;
            }

            &__content {
                &::v-deep {
                    h2 {
                        @include font("text-md-upper");
                        color: $color-primary;
                        margin-bottom: 0.625rem;
                    }

                    h3 {
                        @include font("text-md");
                        margin-bottom: 0.25rem;
                    }

                    p {
                        color: $color-black;
                        @include font("text");
                    }
                }
            }
        }

        &__right {
            position: relative;
            display: flex;

            img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &__content {
                text-decoration: none;
                position: relative;
                padding: 2.5rem 1rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 1.5rem;
                z-index: 0;
                color: $color-white;

                @include desktop {
                    padding: 2rem 4rem 1.5rem 4rem;
                }

                * {
                    z-index: 2;
                    text-align: center;
                    margin: 0;
                }

                ::v-deep p {
                    color: $color-white;
                    margin-bottom: 0.75rem;
                }

                h2 {
                    @include font("headline-bg");
                    color: $color-white;
                }

                &::before {
                    content: "";
                    width: 100%;
                    height: 100%;
                    pointer-events: none;
                    position: absolute;
                    left: 0;
                    top: 0;
                    background-color: rgba($color-secondary, 0.8);
                    z-index: 1;
                }
            }

            &__buttons {
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 1rem;
                width: 100%;

                & > * {
                    width: 100%;
                }
            }
        }
    }
</style>
