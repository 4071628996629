<template>
    <main
        id="home"
        class="home">
        <section
            v-if="item"
            class="content">
            <Statusboard
                v-if="item && statistics"
                :item="item"
                :statistics="statistics" />

            <template v-for="block in item.content">
                <component
                    :is="getBlockComponent(block)"
                    :block="block" />
            </template>
        </section>
        <section
            v-else
            class="content">
            <Teasers />
            <CmsCounters />
            <CmsSponsors />
        </section>
    </main>
</template>

<script>
    import Teasers from "@/modules/Home/components/Teasers.vue";
    import CmsCounters from "@/components/cms/blocks/CmsCounters.vue";
    import Statusboard from "@/modules/Home/components/Statusboard.vue";
    import ApiService from "@/services/api.service";
    import CmsPageMixin from "@/components/cms/CmsPageMixin.js";

    export default {
        name: "Home",
        components: {
            Teasers,
            CmsCounters,
            Statusboard
        },
        mixins: [CmsPageMixin],
        data() {
            return {
                type: "homepage",
                fields: [
                    "content",
                    "statusboard_left_content",
                    "statusboard_right_title",
                    "statusboard_right_background_image",
                    "statusboard_right_content",
                    "statusboard_right_button_label_1",
                    "statusboard_right_button_label_2",
                    "statusboard_right_button_link_1",
                    "statusboard_right_button_link_2",
                    "seo_title",
                    "search_description"
                ],
                statistics: undefined
            };
        },
        computed: {
            isLoggedIn() {
                return this.$store.getters["auth/isLoggedIn"];
            },
            settings() {
                return this.$store.state.settings.settings;
            }
        },
        methods: {
            afterPageLoad() {
                return ApiService.get("/statistics").then(response => {
                    this.statistics = {
                        totalMoney: response.data.money,
                        finishedLaps: response.data.finished_laps,
                        meters: response.data.meters
                    };
                });
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "~@/styles/variables";

    .cms-block-text {
        ::v-deep h2 {
            color: $color-primary !important;
            font-size: 1.5rem;
            font-weight: 900;
            text-transform: uppercase;
            line-height: 1.15;
        }
    }

    .timer-block {
        display: block;
        margin: 5rem 0;
    }
</style>
