import Vue from "vue";
import App from "./App.vue";
import BackOfficeApp from "./BackOfficeApp.vue";
import store from "./store";
import router from "./router";
import i18n from "./locales";
import VueBrowserUpdate from "vue-browserupdate/index";

import "vue-select/dist/vue-select.css";

Vue.config.productionTip = false;

new Vue({
    i18n,
    store,
    router,
    render: function (h) {
        if (store.state.routing.backOfficeRoute) return h(BackOfficeApp);
        return h(App);
    }
}).$mount("#app");

Vue.use(VueBrowserUpdate, {
    options: {
        nostatistics: true
    }
});
