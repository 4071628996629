<template>
    <a
        :href="href"
        :target="target"
        class="card">
        <figure class="card-figure">
            <img
                v-if="innerSrc"
                class="card-img"
                :src="innerSrc"
                :alt="alt" />
            <figcaption
                v-if="hasSlot"
                class="card-figcaption">
                <div class="card-figcaption-inner">
                    <slot />
                </div>
            </figcaption>
        </figure>
        <button
            v-if="title"
            class="card-button">
            {{ title }}
        </button>
    </a>
</template>

<script>
    import ApiService from "@/services/api.service";

    export default {
        name: "Card",
        props: {
            href: String,
            src: String,
            title: String,
            text: String,
            target: String,
            imageId: Number
        },
        data() {
            return {
                innerSrc: "",
                alt: ""
            };
        },
        computed: {
            hasSlot() {
                return !!this.$slots.default;
            }
        },
        mounted() {
            if (!this.imageId) {
                this.innerSrc = this.src;
                this.alt = this.title;
            } else {
                const url = `/images/${this.imageId}`;
                ApiService.get(url).then(
                    response => (
                        (this.innerSrc = response.data.meta.download_url),
                        (this.alt = response.data.title)
                    )
                );
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "~@/styles/variables";
    @import "~@/styles/mixins";

    .card {
        display: block;
        text-decoration: none;

        &-figure {
            position: relative;
        }

        &-figcaption {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background-color: rgba(102, 153, 153, 0.75);
            color: $color-white;
        }

        &-figcaption-inner {
            text-align: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            width: 420px;
            max-width: 100%;
            border-color: inherit;
            box-sizing: border-box;
            font-size: 2rem;
            font-weight: 900;
            text-transform: uppercase;
            line-height: 3rem;

            @include desktop {
                padding: 1em;
            }
        }

        &-img {
            display: block;
            width: 100%;
            height: 340px;
            object-fit: cover;
            background-color: $color-white;
        }

        &-button {
            display: block;
            width: 100%;
            margin: 0;
            border-radius: 0;
            border: none;
            height: 86px;
            position: relative;
            text-align: right;
            padding-right: 7.5rem;
            font-weight: 900;
            font-size: 1.125rem;
            background-color: $color-primary;
            color: $color-white;

            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 2.5rem;
                width: 2.5rem;
                height: 0;
                color: $color-white;
                border-color: inherit;
                border-top: 0.25em solid;
                box-sizing: border-box;
            }

            &:after {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                margin-top: -0.135em;
                right: 45px;
                width: 1em;
                height: 1em;
                color: $color-white;
                border-color: inherit;
                border-top: 0.25em solid;
                border-right: 0.25em solid;
                transform: rotate(45deg) translateY(-50%);
                box-sizing: border-box;
            }
        }
    }
</style>
