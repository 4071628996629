import ApiService from "@/services/api.service";

export default {
    data() {
        return {
            menuItems: []
        };
    },
    computed: {
        language() {
            return this.$store.state.auth.language;
        }
    },
    watch: {
        language() {
            this.getMenuItems();
        }
    },
    mounted() {
        this.getMenuItems();
    },
    methods: {
        getMenuItems() {
            const url =
                "/pages/?fields=title,children&show_in_menus&format=json&type=cms.MenuItemPage&show_in_menus=true";
            ApiService.get(url, true).then(
                response => (this.menuItems = response.data.items)
            );
        }
    }
};
