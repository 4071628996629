<template>
    <div class="ranking">
        <h3 class="ranking__left">
            {{ element.index + 1 }}
        </h3>
        <Avatar
            v-if="showAvatar"
            :runner="element" />
        <h3 class="ranking__center">
            {{ element.name }}
        </h3>
        <h3 class="list__right">
            {{ element.value }}
        </h3>
    </div>
</template>

<script>
    import Avatar from "@/components/Avatar.vue";
    export default {
        name: "ListItemRanking",
        components: { Avatar },
        props: {
            element: {
                type: Object,
                default: () => {}
            },
            showAvatar: {
                type: Boolean,
                default: true
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "~@/styles/variables";

    .ranking {
        flex-grow: 1;
        display: flex;
        align-items: center;
        gap: 0.625rem;

        &__left {
            width: 2rem;
        }
    }
</style>
