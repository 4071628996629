<template>
    <div class="message-manager">
        <transition-group
            name="list"
            tag="ol"
            class="message-list">
            <li
                v-for="messageObject in messageList"
                :key="messageObject.id"
                :class="['message', messageObject.type]">
                <span class="message-text">{{ messageObject.message }}</span>
                <button
                    class="message-close-button"
                    @click.prevent="closeMessage($event, messageObject)">
                    <i
                        class="icon close-sm"
                        :class="{ red: messageObject.type !== 'success' }"
                        aria-hidden="true" />
                </button>
            </li>
        </transition-group>
    </div>
</template>

<script>
    export default {
        name: "MessageManager",
        data() {
            return {};
        },
        computed: {
            messages() {
                return this.$store.state.messages.messages;
            },
            messageList() {
                return this.messages
                    .slice()
                    .sort(this.sortMessages)
                    .slice(0, 3)
                    .reverse();
            }
        },
        mounted() {
            const timeout = 1000; // Check message 1 seconds
            this.messageDisplayInterval = setInterval(
                this.checkMessages,
                timeout
            );
        },
        beforeDestroy() {
            clearInterval(this.messageDisplayInterval);
        },
        methods: {
            sortMessages(a, b) {
                return a.timestamp >= b.timestamp;
            },
            closeMessage(e, message) {
                this.$store.commit("messages/removeMessage", message.id);
            },
            checkMessages() {
                const messageTtl = 5000; // Delete message after 5 seconds
                const messageList = this.$store.state.messages.messages.slice();
                const message = messageList.shift();
                if (
                    typeof message !== "undefined" &&
                    message.timestamp < new Date(Date.now() - messageTtl)
                )
                    this.$store.commit("messages/shiftMessage");
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "~@/styles/variables";
    @import "~@/styles/mixins";

    .message-manager {
        position: fixed;
        bottom: 1rem;
        left: 1rem;
        right: 1rem;
        z-index: 10000;

        @include desktop {
            bottom: 2.5rem;
            left: 2.5rem;
            right: 2.5rem;
        }

        ol {
            width: 100%;
            max-width: $content-width;
            margin: 0 auto;
            padding: 0;
            list-style: none;

            .list-enter {
                opacity: 0.1;
            }

            .list-leave-to {
                opacity: 0.1;
            }

            li {
                @include font("headline-sub");
                background-color: $color-white;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 1rem;
                padding: 1rem 1.5rem;
                margin-top: 1rem;
                transition: all 500ms;
                border-top: 0.25rem solid;
                box-shadow: 0 4px 12px 0 #00000028;

                .message-close-button {
                    margin: 0;
                    padding: 0;
                    background: none;
                    border: none;
                    display: flex;

                    i {
                        margin: 0;
                    }
                }

                &.success {
                    color: $color-black;
                    border-color: $color-secondary;
                }

                &.warning,
                &.error {
                    color: $color-error;
                    border-color: $color-error;
                }
            }
        }
    }
</style>
