<template>
    <form
        class="form"
        :class="classes"
        @submit.prevent="submit"
        novalidate>
        <div class="form__field form__field--label-headline">
            <label>{{ $t("Login form") }}</label>
            <TextInput
                id="email"
                v-model="form.email"
                type="email"
                placeholder="E-Mail"
                :error="errors.email"
                :force-error="forceErrors" />
        </div>
        <button class="form__submit">
            {{ $t("Send one time password") }}
        </button>
    </form>
</template>

<script>
    import FormMixin from "./FormMixin";

    export default {
        name: "FormLogin",
        mixins: [FormMixin],
        data() {
            return {
                form: {
                    email: ""
                },
                errors: {
                    email: ""
                }
            };
        },
        computed: {
            isLoggedIn() {
                return this.$store.getters["auth/isLoggedIn"];
            },
            messages() {
                return {
                    successMessage: this.$t(
                        "Thank you for your login request, you should receive an e-mail with your registration code."
                    ),
                    errorMessage: this.$t(
                        "Something went wrong, please check your information and try again later."
                    )
                };
            }
        },
        watch: {
            "form.email"() {
                this.validate();
            }
        },
        methods: {
            submit() {
                if (!this.valid) return (this.forceErrors = true);
                this.$store
                    .dispatch("auth/login", {
                        email: this.form.email,
                        successMessage: this.messages.successMessage,
                        errorMessage: this.messages.errorMessage,
                        sendEmail: true
                    })
                    .then(() =>
                        this.$router.push({
                            name: "auth",
                            query: { email: this.form.email }
                        })
                    );
            },
            validate() {
                const emailRegex =
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (!this.form.email)
                    this.setError("email", this.$t("This field is required"));
                else if (!emailRegex.test(this.form.email))
                    this.setError(
                        "email",
                        this.$t("This does not look like a valid e-mail")
                    );
                else this.deleteError("email");
            }
        }
    };
</script>
