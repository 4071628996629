import Vue from "vue";
import VueI18n from "vue-i18n";
import de from "@/locales/de";
import fr from "@/locales/fr";

Vue.use(VueI18n);

export const messages = {
    de,
    fr
};

export const locales = [
    {
        value: "de",
        name: "German",
        native: "Deutsch",
        http: "de-CH,de;q=0.9,en-US;q=0.8,en;q=0.7"
    },
    {
        value: "fr",
        name: "French",
        native: "Français",
        http: "fr-CH,fr;q=0.9,it-IT;q=0.8,it;q=0.7"
    }
];

export const mapping = {
    fr: "fr",
    la: "fr",
    it: "fr",
    es: "fr",
    pt: "fr",
    en: "de"
};

export const mapLocale = lang => {
    if (!lang) return locales[0].value;
    let language = lang.substring(0, 2);

    const hasLanguage = Object.keys(mapping).includes(language);
    return hasLanguage ? mapping[language] : locales[0].value;
};

export const fallbackLocale = process.env.VUE_APP_I18N_LOCALE;

export const getDefaultLocale = () => {
    let lang = fallbackLocale;
    const userLanguages = window.navigator.languages;

    for (let userLanguage of userLanguages) {
        let userLang = mapLocale(userLanguage);

        if (
            userLanguage.substring(0, 2) === userLang &&
            Object.keys(messages).includes(userLang)
        )
            lang = userLang;
    }

    return lang || locales.shift().value;
};

export const defaultLocale = getDefaultLocale();

export default new VueI18n({
    locale: defaultLocale,
    fallbackLocale: fallbackLocale,
    messages
});
