<template>
    <div class="cms-block cms-block-counters">
        <img src="@/assets/icons/icon-heart-outline.png" />
        <h2>{{ $t("Many thanks for your support!") }}</h2>
        <div class="cms-block-counters__columns">
            <RunnerCounter />
            <SponsorCounter />
            <CountdownTimer />
        </div>
    </div>
</template>

<script>
    import RunnerCounter from "@/components/RunnerCounter.vue";
    import SponsorCounter from "@/components/SponsorCounter.vue";
    import CountdownTimer from "@/components/CountdownTimer.vue";
    import CmsBlockMixin from "./CmsBlockMixin";

    export default {
        name: "CmsCounters",
        components: {
            RunnerCounter,
            SponsorCounter,
            CountdownTimer
        },
        mixins: [CmsBlockMixin]
    };
</script>

<style scoped lang="scss">
    @import "~@/styles/variables";
    @import "~@/styles/mixins";

    .cms-block-counters {
        padding: 2.5rem 1rem;
        display: flex;
        flex-direction: column;
        gap: 2.5rem;
        align-items: center;
        text-align: center;
        text-transform: uppercase;

        img {
            width: 150px;
        }

        &__columns {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            gap: 1rem;

            @include desktop {
                width: calc(75% - 2.375rem);
                grid-template-columns: repeat(3, 1fr);
                gap: 1.5rem;
            }
        }
    }
</style>
