import ApiService from "../services/api.service";
import { parseJwt } from "../utils";
import i18n, { mapLocale, locales } from "@/locales";
import Cookies from "js-cookie";

const actionsObj = {
    // Here we send just the e-mail address and the user should receive an one time password
    // If the user is not registered an account will be created in the background
    login: async function (
        { commit, state, dispatch },
        { email, errorMessage = null, successMessage = null, sendEmail = true }
    ) {
        return new Promise((resolve, reject) => {
            const _email = email || state.user.email || null;

            commit("setLoading", true);
            let url = `login/${_email}/`;

            if (sendEmail) url += "?sendEmail=true";

            ApiService.get(url)
                .then(response => {
                    const user = {
                        id: response.data.id,
                        email: _email
                    };
                    commit("messages/setSuccessMessage", successMessage, {
                        root: true
                    });
                    commit("setUser", user);
                    commit("setUserCookie");
                    resolve(response);
                })
                .catch(error => {
                    if (
                        Object.keys(error).includes("response") &&
                        error.response.status === 404
                    ) {
                        // If the response is 404 not found, the user does not exists
                        // So we try to create a new one
                        const payload = {
                            email: _email,
                            errorMessage: errorMessage,
                            successMessage: successMessage
                        };
                        resolve(dispatch("create", payload));
                    } else {
                        commit(
                            "messages/setErrorMessage",
                            errorMessage || error.response || null,
                            {
                                root: true
                            }
                        );
                        reject(error);
                    }
                });
        });
    },

    // Create a new account and send an email with the registration code
    create: async function (
        { commit, state },
        { email, language, errorMessage = null, successMessage = null }
    ) {
        return new Promise((resolve, reject) => {
            const _email = email || state.user.email || null;
            const _language = language || state.language || null;
            const data = {
                email: _email,
                language: _language
            };

            commit("setLoading", true);

            ApiService.post("register/", data)
                .then(response => {
                    const user = {
                        id: response.data.id,
                        email: _email
                    };
                    commit("messages/setSuccessMessage", successMessage, {
                        root: true
                    });
                    commit("setUser", user);
                    commit("setUserCookie");
                    resolve(response);
                })
                .catch(error => {
                    commit(
                        "messages/setErrorMessage",
                        errorMessage || error.response,
                        {
                            root: true
                        }
                    );
                    reject(error);
                })
                .finally(() => {
                    commit("setLoading", false);
                });
        });
    },

    // Get JWT access and refresh tokens, save in vuex store, localStorage (service) and set http headers (bearer)
    auth: async function (
        { commit, state, dispatch },
        { email, password, errorMessage = null, successMessage = null }
    ) {
        return new Promise((resolve, reject) => {
            const _email = email || state.user.email || null;
            const data = {
                email: _email,
                password: password
            };

            commit("setLoading", true);

            ApiService.post("token/", data)
                .then(response => {
                    commit("messages/setSuccessMessage", successMessage, {
                        root: true
                    });
                    commit("setTokens", response.data);
                    commit("setTokenCookies", response.data);
                    ApiService.enableAuth(state.access);
                    resolve(dispatch("user", { id: state.user.id }));
                })
                .catch(error => {
                    commit(
                        "messages/setErrorMessage",
                        errorMessage || error.response,
                        {
                            root: true
                        }
                    );
                    reject(error);
                })
                .finally(() => {
                    commit("setLoading", false);
                });
        });
    },

    user: async function (
        { commit, state },
        { id = null, errorMessage = null, successMessage = null } = {}
    ) {
        return new Promise((resolve, reject) => {
            const _id = id || state.user.id || null;

            commit("setLoading", true);

            ApiService.get("user/" + _id + "/")
                .then(async response => {
                    const user = {
                        id: response.data.id,
                        firstname: response.data.first_name,
                        lastname: response.data.last_name,
                        sponsoring: response.data.sponsoring || [],
                        sponsoredBy: response.data.sponsored_by || [],
                        runner: {
                            id: response.data.runner
                                ? response.data.runner
                                : null,
                            total_chf: response.data.runner
                                ? response.data.runner.total_chf
                                : null,
                            number_of_laps: response.data.runner
                                ? response.data.runner.number_of_laps
                                : null
                        },
                        invoiceAddress: {
                            id: response.data.invoice_address
                                ? response.data.invoice_address
                                : null,
                            total_chf: response.data.invoice_address
                                ? response.data.invoice_address.total_chf
                                : null
                        }
                    };

                    if (user.runner.id)
                        await ApiService.get(
                            "runner/" + user.runner.id + "/"
                        ).then(response => {
                            user.runner = response.data;
                        });

                    if (user.invoiceAddress.id)
                        await ApiService.get(
                            "invoice_address/" + user.invoiceAddress.id + "/"
                        ).then(response => {
                            user.invoiceAddress = response.data;
                        });

                    if (user.runner.street === "null")
                        user.runner.street = null;
                    if (user.runner.zip === "null") user.runner.zip = null;

                    commit("messages/setSuccessMessage", successMessage, {
                        root: true
                    });
                    commit("setUser", user);
                    commit("setUserCookie");
                    resolve(response);
                })
                .catch(error => {
                    commit(
                        "messages/setErrorMessage",
                        errorMessage || error.response,
                        {
                            root: true
                        }
                    );
                    reject(error);
                })
                .finally(() => {
                    commit("setLoading", false);
                });
        });
    },

    register: async function (
        { commit, state },
        { id, firstname, lastname, errorMessage = null, successMessage = null }
    ) {
        return new Promise((resolve, reject) => {
            const _id = id || state.user.id || null;
            const data = {
                first_name: firstname,
                last_name: lastname
            };

            commit("setLoading", true);

            ApiService.put("user/" + _id + "/", data)
                .then(response => {
                    commit("messages/setSuccessMessage", successMessage, {
                        root: true
                    });
                    commit("setUser", { firstname, lastname });
                    commit("setUserCookie");
                    resolve(response);
                })
                .catch(error => {
                    commit(
                        "messages/setErrorMessage",
                        errorMessage || error.response,
                        {
                            root: true
                        }
                    );
                    commit("flushUserCookie");
                    reject(error);
                })
                .finally(() => {
                    commit("setLoading", false);
                });
        });
    },

    registerRunner: async function (
        { commit, state },
        {
            id,
            health_check,
            city,
            zip,
            street,
            avatar = null,
            errorMessage = null,
            successMessage = null,
            group_membership = null
        }
    ) {
        return new Promise((resolve, reject) => {
            const _id = id || state.user.id || null;
            const data = new FormData();
            data.append("user", _id);
            data.append("health_check", health_check);
            data.append("city", city);
            data.append("street", street);
            data.append("zip", zip);
            data.append(
                "group_membership",
                group_membership ? group_membership : ""
            );
            if (avatar) data.append("avatar", avatar);

            const config = {
                headers: {
                    "content-type": "multipart/form-data"
                }
            };

            commit("setLoading", true);

            ApiService.post("runner/", data, config)
                .then(response => {
                    commit("messages/setSuccessMessage", successMessage, {
                        root: true
                    });
                    commit("setRunner", { ...response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit(
                        "messages/setErrorMessage",
                        errorMessage || error.response,
                        {
                            root: true
                        }
                    );
                    reject(error);
                })
                .finally(() => {
                    commit("setLoading", false);
                });
        });
    },

    updateRunner: async function (
        { commit, state },
        {
            id,
            city,
            zip = null,
            street = null,
            avatar = null,
            errorMessage = null,
            successMessage = null,
            group_membership = null,
            preventLoading = false
        }
    ) {
        return new Promise((resolve, reject) => {
            const _id = id || state.user.runner.id || null;
            const data = new FormData();
            data.append("health_check", true);
            data.append("city", city);
            data.append("zip", zip);
            data.append("street", street);
            data.append(
                "group_membership",
                group_membership ? group_membership : ""
            );
            if (avatar) data.append("avatar", avatar);

            const config = {
                headers: {
                    "content-type": "multipart/form-data"
                }
            };

            if (!preventLoading) {
                commit("setLoading", true);
            }

            ApiService.put("runner/" + _id + "/", data, config)
                .then(response => {
                    commit("messages/setSuccessMessage", successMessage, {
                        root: true
                    });
                    commit("setRunner", { ...response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit(
                        "messages/setErrorMessage",
                        errorMessage || error.response,
                        {
                            root: true
                        }
                    );
                    reject(error);
                })
                .finally(() => {
                    commit("setLoading", false);
                });
        });
    },

    registerInvoiceAddress: async function (
        { commit },
        { address, successMessage = null, errorMessage = null }
    ) {
        return new Promise((resolve, reject) => {
            const data = {
                ...address
            };

            commit("setLoading", true);

            ApiService.post("/invoice_address/", data)
                .then(response => {
                    commit("messages/setSuccessMessage", successMessage, {
                        root: true
                    });
                    commit("setUser", { invoiceAddress: { ...response.data } });
                    commit("setUserCookie");
                    resolve(response);
                })
                .catch(error => {
                    commit(
                        "messages/setErrorMessage",
                        errorMessage || error.response,
                        {
                            root: true
                        }
                    );
                    reject(error);
                })
                .finally(() => {
                    commit("setLoading", false);
                });
        });
    },

    updateInvoiceAddress: async function (
        { commit, state },
        { id, address, successMessage = null, errorMessage = null }
    ) {
        return new Promise((resolve, reject) => {
            const _id = id || state.user.invoiceAddress.id || null;
            const data = {
                ...address
            };

            commit("setLoading", true);

            ApiService.put("/invoice_address/" + _id + "/", data)
                .then(response => {
                    commit("messages/setSuccessMessage", successMessage, {
                        root: true
                    });
                    commit("setUser", { invoiceAddress: { ...response.data } });
                    commit("setUserCookie");
                    resolve(response);
                })
                .catch(error => {
                    commit(
                        "messages/setErrorMessage",
                        errorMessage || error.response,
                        {
                            root: true
                        }
                    );
                    reject(error);
                })
                .finally(() => {
                    commit("setLoading", false);
                });
        });
    },

    addSponsoring: async function (
        { commit, state },
        {
            runnerId,
            sponsoringMethod,
            amount,
            successMessage = null,
            errorMessage = null
        }
    ) {
        return new Promise((resolve, reject) => {
            const data = {
                runner: runnerId,
                method: sponsoringMethod,
                amount
            };

            commit("setLoading", true);

            ApiService.post("sponsoring/", data)
                .then(response => {
                    commit("messages/setSuccessMessage", successMessage, {
                        root: true
                    });
                    commit("addSponsoring", { ...response.data });
                    if (runnerId === state.user.runner.id)
                        commit("addSponsoredBy", { ...response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit(
                        "messages/setErrorMessage",
                        errorMessage || error.response,
                        {
                            root: true
                        }
                    );
                    reject(error);
                })
                .finally(() => {
                    commit("setLoading", false);
                });
        });
    },

    refresh: async function (
        { commit, state },
        { refresh = null, errorMessage = null, successMessage = null }
    ) {
        return new Promise((resolve, reject) => {
            const _refresh = refresh || state.refresh || null;
            const data = {
                refresh: _refresh
            };

            commit("setLoading", true);

            ApiService.post("token/refresh/", data)
                .then(response => {
                    commit("messages/setSuccessMessage", successMessage, {
                        root: true
                    });
                    commit("setTokens", {
                        access: response.data.access,
                        refresh: state.refresh
                    });
                    commit("setTokenCookies", {
                        access: response.data.access,
                        refresh: state.refresh
                    });
                    ApiService.enableAuth(state.access);
                })
                .catch(error => {
                    commit(
                        "messages/setErrorMessage",
                        errorMessage || error.response,
                        {
                            root: true
                        }
                    );
                    reject(error);
                })
                .finally(() => {
                    commit("setLoading", false);
                });
        });
    },

    checkAuth: async function (
        { dispatch, commit, state },
        {
            errorMessage = null,
            successMessage = null,
            refreshErrorMessage = null,
            refreshSuccessMessage = null
        }
    ) {
        return new Promise((resolve, reject) => {
            const { access, refresh } = state;
            const now = new Date();
            if (!access || !refresh) reject(new Error("No tokens are set!"));

            // Javascript's Date() takes milliseconds as an argument. Python's uses seconds.
            // You have to multiply by 1,000.
            // Without this, the expiration date is always in the past.
            const accessExpiration = new Date(parseJwt(access).exp * 1000);
            const refreshExpiration = new Date(parseJwt(refresh).exp * 1000);

            if (accessExpiration < now)
                if (refreshExpiration >= now) {
                    // Token has expired
                    // Refresh has not expired
                    const payload = {
                        refresh,
                        successMessage: refreshSuccessMessage,
                        errorMessage: refreshErrorMessage
                    };
                    resolve(dispatch("refresh", payload));
                } else {
                    commit("messages/setErrorMessage", errorMessage, {
                        root: true
                    });
                    reject(new Error(errorMessage));
                }
            else {
                commit("messages/setSuccessMessage", successMessage, {
                    root: true
                });
                resolve({ successMessage });
            }
        });
    },

    // @ToDO: This just clears the tokens on the user side, the token are still valid on the server
    // @ToDo: We should build and call an endpoint which correctly invalidates all existing tokens
    async logout({ commit }) {
        commit("setTokens", {
            access: null,
            refresh: null
        });
        commit("setUser", {
            id: null,
            email: null,
            firstname: null,
            lastname: null,
            invoiceAddress: {
                id: null,
                street: null,
                zip: null,
                city: null,
                company: null
            },
            runner: {
                id: null,
                health_check: null,
                avatar: null,
                bio: null,
                city: null,
                street: null,
                number_of_laps: 0
            },
            sponsoring: [],
            sponsoredBy: []
        });
        Cookies.remove("access");
        Cookies.remove("refresh");
        Cookies.remove("user");
        ApiService.disableAuth();
    },

    updateLanguage: async function ({ commit }, { lang }) {
        const language = mapLocale(lang);
        i18n.locale = language;
        ApiService.setAcceptedLanguage(
            locales.filter(obj => obj.value === language)[0].value
        );
        commit("setLanguage", language);
    }
};

const stateObj = {
    access: null,
    refresh: null,
    loading: false,
    language: null,
    user: {
        id: null,
        email: null,
        firstname: null,
        lastname: null,
        invoiceAddress: {
            id: null,
            street: null,
            zip: null,
            city: null,
            company: null
        },
        runner: {
            id: null,
            health_check: null,
            avatar: null,
            bio: null,
            city: null,
            street: null,
            zip: null,
            number_of_laps: 0
        },
        sponsoring: [],
        sponsoredBy: []
    }
};

const mutationsObj = {
    // Auth
    setTokens(state, { access, refresh }) {
        state.access = access;
        state.refresh = refresh;
    },

    setTokenCookies(state, { access, refresh }) {
        Cookies.set("access", access, {
            expires: 180,
            secure: true,
            sameSite: "none"
        });
        Cookies.set("refresh", refresh, {
            expires: 180,
            secure: true,
            sameSite: "none"
        });
    },

    // User and related data
    setUser(state, user) {
        state.user = { ...state.user, ...user };
    },
    setUserCookie(state) {
        Cookies.set("user", JSON.stringify(state.user), {
            expires: 180,
            secure: true,
            sameSite: "none"
        });
    },
    flushUserCookie() {
        Cookies.remove("user");
    },
    setUserId(state, id) {
        state.user.id = id;
    },
    setUserEmail(state, email) {
        state.user.email = email;
    },
    setInvoiceAddress(state, invoiceAddress) {
        state.user.invoiceAddress = invoiceAddress;
    },
    setRunner(state, runner) {
        state.user.runner = runner;
        Cookies.set("user", JSON.stringify(state.user), {
            expires: 180,
            secure: true,
            sameSite: "none"
        });
    },
    setSponsoring(state, sponsoring) {
        state.user.sponsoring = sponsoring;
    },
    addSponsoring(state, sponsoring) {
        if (state.user.sponsoring.indexOf(sponsoring.id) === -1)
            state.user.sponsoring.push(sponsoring.id);
    },
    setSponsoredBy(state, sponsoredBy) {
        state.user.sponsoredBy = sponsoredBy;
    },
    addSponsoredBy(state, sponsoredBy) {
        if (state.user.sponsoredBy.indexOf(sponsoredBy.id) === -1)
            state.user.sponsoredBy.push(sponsoredBy.id);
    },
    setLoading(state, enabled) {
        state.loading = enabled;
    },
    setLanguage(state, language) {
        if (typeof language === "string") {
            state.language = language;
            Cookies.set("language", language, {
                expires: 180,
                secure: true,
                sameSite: "none"
            });
        }
    }
};

const gettersObj = {
    // isLoggedIn checks if the access token is set in cookies
    isLoggedIn: state => !!state.access || !!Cookies.get("access")
};

export default {
    namespaced: true,
    actions: actionsObj,
    state: stateObj,
    mutations: mutationsObj,
    getters: gettersObj
};
