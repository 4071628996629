<template>
    <div class="runner-sponsor">
        <Avatar
            :runner="runner"
            class="runner-sponsor__avatar" />
        <div class="runner-sponsor__info">
            <h3>
                {{ runner.last_name }}
                {{ runner.first_name }}
            </h3>
            <span>{{ runner.group_name }}</span>
            <span>{{ runner.city }}</span>
        </div>
        <div class="runner-sponsor__button">
            <router-link
                v-if="!phaseIsAfterRun"
                class="button button--secondary"
                :to="getSponsorLink(runner)">
                {{ $t("Sponsor") }}
            </router-link>
        </div>
    </div>
</template>

<script>
    import { INTENTS } from "@/store/intent";
    import { PHASES } from "@/constants.js";
    import Avatar from "@/components/Avatar.vue";

    export default {
        name: "ListItemRunnerSponsor",
        components: { Avatar },
        props: {
            runner: {
                type: Object,
                default: () => {}
            }
        },
        computed: {
            phase() {
                return this.$store.state.settings.phase;
            },
            phaseIsAfterRun() {
                return this.phase === PHASES.AFTER_RUN;
            }
        },
        methods: {
            getSponsorLink(runner) {
                return {
                    name: this.isLoggedIn ? "add_sponsoring" : "login",
                    params: {
                        lang: this.$i18n.locale
                    },
                    query: {
                        intent: INTENTS.SPONSOR.toLowerCase(),
                        runner: runner.id
                    }
                };
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "~@/styles/variables";
    @import "~@/styles/mixins";

    .runner-sponsor {
        padding: 0;
        margin: 0;

        $column-gap: 0.625rem;
        $avatar-width: 65px;

        display: grid;
        grid-template-columns: $avatar-width calc(
                100% - $avatar-width - $column-gap
            );
        column-gap: $column-gap;
        row-gap: 1.5rem;
        width: 100%;
        max-width: 100%;

        @include desktop {
            display: flex;
            align-items: center;
        }

        &__info {
            height: 100%;
            align-self: flex-start;
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
            word-wrap: break-word;
        }

        &__button {
            grid-column: 2 / 3;
            grid-row: 2 / 3;

            @include desktop {
                margin-left: calc(2.5rem - $column-gap);
                flex-grow: 1;
                display: flex;
                justify-content: flex-end;
            }
        }
    }
</style>
