import ApiService from "@/services/api.service";
import CmsMultiCol from "./CmsMultiCol.vue";
import CmsHeaderImage from "./CmsHeaderImage.vue";
import Blocks, { getBlockComponent } from "./blocks";

export default {
    components: { ...Blocks, CmsMultiCol, CmsHeaderImage },
    data() {
        return {
            item: {},
            type: "",
            fields: []
        };
    },
    computed: {
        isPreview() {
            return this.$route.name.includes("page-preview");
        },
        pageUrl() {
            let url = `/pages/?${this.encodedFields}&format=json&type=cms.${this.type}`;
            const extraParams = this.extraParams;
            for (let key of Object.keys(extraParams)) {
                url += `&${key}=${extraParams[key]}`;
            }
            return url;
        },
        encodedFields() {
            return "fields=" + this.fields.join(",");
        },
        extraParams() {
            return {};
        }
    },
    watch: {
        $route() {
            this.initPage();
        }
    },
    mounted() {
        this.initPage();
    },
    methods: {
        getBlockComponent(block) {
            switch (block.type) {
                case "multiColumns":
                    return "CmsMultiCol";
                default:
                    return getBlockComponent(block);
            }
        },
        initPage() {
            if (this.isPreview) this.getPreviewPage();
            else this.getPage();
        },
        getPreviewPage() {
            const url = `/page_preview/?${this.encodedFields}&format=json&content_type=${this.$route.query.content_type}&token=${this.$route.query.token}`;

            this.$store.commit("auth/setLoading", true);

            ApiService.get(url, true)
                .then(response => response.data)
                .then(this.preparePage.bind(this))
                .then(this.afterPageLoad.bind(this))
                .catch(this.fallback.bind(this))
                .finally(() => {
                    this.$store.commit("auth/setLoading", false);
                });
        },
        getPage() {
            this.$store.commit("auth/setLoading", true);

            ApiService.get(this.pageUrl, true)
                .then(response => {
                    const items = response.data.items;
                    if (!items || !items.length)
                        throw new Error("The requested slug is not a cms page");

                    return items.shift();
                })
                .then(this.preparePage.bind(this))
                .then(this.afterPageLoad.bind(this))
                .catch(this.fallback.bind(this))
                .finally(() => {
                    this.$store.commit("auth/setLoading", false);
                });
        },
        preparePage(page) {
            this.item = page;
            this.$store.dispatch("seo/setTitle", page.meta.seo_title);
            this.$store.dispatch(
                "seo/setDescription",
                page.meta.search_description
            );
        },
        afterPageLoad() {
            return;
        },
        fallback(error) {
            this.$router.push({
                name: "home",
                params: {
                    lang: this.$route.params.lang
                }
            });

            return error;
        }
    }
};
