export default {
    name: "InputMixin",
    props: {
        id: {
            type: String,
            default: ""
        },
        name: {
            type: String,
            default: ""
        },
        placeholder: {
            type: String,
            default: ""
        },
        error: {
            type: String,
            default: ""
        },
        forceError: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showError: false,
            wasAlreadyFilled: false
        };
    },
    watch: {
        value() {
            this.wasAlreadyFilled = this.wasAlreadyFilled || this.filled;
        }
    },
    computed: {
        innerValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        },
        filled() {
            return !!this.innerValue;
        },
        classes() {
            const classes = ["control"];
            if (this.filled) classes.push("control--filled");
            if (this.error && (this.showError || this.forceError))
                classes.push("control--error");
            return classes;
        },
        placeholderClasses() {
            return ["control__placeholder"];
        },
        errorClasses() {
            return ["control__error"];
        }
    },
    methods: {
        handleBlur() {
            this.showError =
                this.filled || this.wasAlreadyFilled || this.showError;
        }
    }
};
