import axios from "axios";
import { uuidv4 } from "../utils";

const ApiService = {
    locale: "de",

    init(baseURL) {
        axios.defaults.baseURL = baseURL;
    },

    setDefaultHeader(header, value) {
        axios.defaults.headers.common[header] = value;
    },

    removeDefaultHeader(header) {
        delete axios.defaults.headers.common[header];
    },

    enableAuth(accessToken) {
        this.setDefaultHeader("Authorization", `Bearer ${accessToken}`);
    },

    disableAuth() {
        this.removeDefaultHeader("Authorization");
    },

    setAcceptedLanguage(locale) {
        this.locale = locale;
        this.setDefaultHeader("Accept-Language", this.locale);
    },

    getAcceptedLanguage() {
        return this.locale;
    },

    get(resource, useLocale = false) {
        return useLocale
            ? axios.get(this.addLocale(resource))
            : axios.get(resource);
    },

    post(resource, data) {
        if (!Object.prototype.hasOwnProperty.call(data, "uuid")) {
            data["uuid"] = uuidv4();
        }
        return axios.post(resource, data);
    },

    put(resource, data) {
        return axios.put(resource, data);
    },

    patch(resource, data) {
        return axios.patch(resource, data);
    },

    delete(resource) {
        return axios.delete(resource);
    },

    addLocale(url) {
        return `${url}&locale=${this.locale}`;
    },

    /**
     * Perform a custom Axios request.
     *
     * data is an object containing the following properties:
     *  - method
     *  - url
     *  - data ... request payload
     *  - auth (optional)
     *    - username
     *    - password
     * */
    customRequest(data) {
        return axios(data);
    }
};

ApiService.init(process.env.VUE_APP_API_ROOT);

export default ApiService;
