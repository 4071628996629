<template>
    <div
        class="cms-block cms-block-button"
        :class="classes">
        <a
            :class="[
                'button',
                `button--${block.value.button_style.toLowerCase()}`
            ]"
            :href="block.value.url">
            {{ block.value.title }}
        </a>
    </div>
</template>

<script>
    import CmsBlockMixin from "./CmsBlockMixin";

    export default {
        name: "CmsButton",
        mixins: [CmsBlockMixin],
        computed: {
            classes() {
                const classes = [];
                if (this.block.value.align)
                    classes.push(
                        "cms-block-button--" +
                            this.block.value.align.toLowerCase()
                    );
                return classes;
            }
        }
    };
</script>

<style scoped lang="scss">
    .cms-block-button {
        display: block;
        text-align: center;
        display: flex;
        justify-content: center;
        margin: 2.5rem 0;

        &--left {
            justify-content: start;
        }

        &--center {
            justify-content: center;
        }

        &--right {
            justify-content: end;
        }
    }
</style>
