<template>
    <RankingList
        class="cms-block"
        :elements="block.value.rows" />
</template>

<script>
    import CmsBlockMixin from "./CmsBlockMixin";
    import RankingList from "@/components/lists/RankingList.vue";

    export default {
        name: "CmsRunnerRanking",
        components: { RankingList },
        mixins: [CmsBlockMixin]
    };
</script>
