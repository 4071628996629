<template>
    <div class="cms-block cms-block-cards">
        <template v-for="element in block.value">
            <Card
                v-if="element.type == 'full_width_card'"
                :key="element.value.link_label"
                class="main-card"
                :image-id="element.value.image"
                :title="element.value.link_label"
                :href="element.value.link_url">
                <i class="color-secondary-light">
                    {{ element.value.upper_title.toUpperCase() }}
                </i>
                <br />
                {{ element.value.lower_title.toUpperCase() }}
            </Card>
            <div
                v-else
                class="columns decorated">
                <Card
                    v-for="card in element.value"
                    :key="card.link_label"
                    class="sub-card"
                    :image-id="card.image"
                    :href="card.link_url"
                    :title="card.link_label" />
            </div>
        </template>
    </div>
</template>

<script>
    import Card from "@/components/Card.vue";
    import CmsBlockMixin from "./CmsBlockMixin";

    export default {
        name: "CmsCards",
        components: {
            Card
        },
        mixins: [CmsBlockMixin]
    };
</script>

<style scoped lang="scss">
    @import "~@/styles/variables";
    @import "~@/styles/mixins";

    .cms-block-cards {
        .columns {
            &.decorated {
                margin-bottom: 185px !important;

                @include desktop {
                    &:before {
                        content: "";
                        position: absolute;
                        bottom: -85px;
                        left: 50%;
                        width: calc(100vw - 1rem);
                        max-width: $app-width;
                        transform: translateX(-50%);
                        height: 832px;
                        background-color: $color-grey-light;
                        z-index: -1;
                    }
                }
            }
        }

        &::v-deep {
            .main-card {
                .card-img {
                    height: 480px;
                }
            }

            .sub-card {
                .card-button {
                    background-color: $color-secondary-light;
                    color: $color-white;
                }
            }
        }
    }
</style>
