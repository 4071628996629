<template>
    <label :class="classes">
        <input
            :id="id || name"
            v-model="innerValue"
            :type="type"
            :name="name || id"
            @blur="handleBlur" />
        <span :class="placeholderClasses">{{ placeholder }}</span>
        <span
            v-if="error && (showError || forceError)"
            :class="errorClasses">
            <i class="icon error inline" />
            {{ error }}
        </span>
    </label>
</template>
<script>
    import InputMixin from "./InputMixin";

    export default {
        name: "TextInput",
        mixins: [InputMixin],
        props: {
            type: {
                type: String,
                default: "text"
            },
            value: {
                type: String,
                default: ""
            }
        }
    };
</script>
