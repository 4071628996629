<template>
    <div
        v-if="title || data"
        class="counter">
        <h3
            v-if="title"
            class="counter__title">
            {{ title }}
        </h3>
        <span
            v-if="data"
            class="counter__data">
            {{ data }}
        </span>
    </div>
</template>

<script>
    export default {
        name: "Counter",
        props: {
            title: String,
            data: String
        }
    };
</script>

<style scoped lang="scss">
    @import "~@/styles/variables";
    @import "~@/styles/mixins";

    .counter {
        padding: 0.625rem 1.5rem;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
        height: 100%;
        text-align: center;
        background-color: $color-secondary;
        color: $color-white;
        box-sizing: border-box;
        width: 280px;

        @include desktop {
            width: auto;
        }

        &__title {
            @include font("headline-sub");
        }

        &__data {
            @include font("headline");
        }
    }
</style>
