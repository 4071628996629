<template>
    <footer class="footer">
        <section class="footer__main">
            <div class="footer__main__wrapper">
                <div class="footer__main__wrapper__start">
                    <TheLionsClubImprint />
                    <TheFooterInfo />
                </div>
                <div class="footer__main__wrapper__end">
                    <FormNewsletter :wide="true" />
                </div>
            </div>
        </section>
        <TheCopyright />
    </footer>
</template>

<script>
    import TheLionsClubImprint from "@/components/layout/footer/TheLionsClubImprint.vue";
    import TheCopyright from "@/components/layout/footer/TheCopyright.vue";
    import TheFooterInfo from "@/components/layout/footer/TheFooterInfo.vue";
    import FormNewsletter from "@/components/form/FormNewsletter.vue";

    export default {
        name: "TheFooter",
        components: {
            FormNewsletter,
            TheCopyright,
            TheLionsClubImprint,
            TheFooterInfo
        }
    };
</script>

<style scoped lang="scss">
    @import "~@/styles/variables";
    @import "~@/styles/mixins";

    .footer {
        width: 100%;

        &__main {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $color-grey-light;

            &__wrapper {
                width: 100%;
                max-width: $content-width;
                display: flex;
                flex-direction: column;
                gap: 2.5rem;
                padding: 1.5rem 1rem;
                box-sizing: border-box;

                @include desktop {
                    padding: 1.5rem 2.5rem;
                    flex-direction: row;
                    gap: 1.5rem;
                    justify-content: space-between;
                }

                $spacing-left: 102px;
                $imprint-width: 77px;

                &__start {
                    display: grid;
                    grid-template-columns: $imprint-width auto;
                    gap: calc($spacing-left - $imprint-width);
                }

                &__end {
                    padding-left: $spacing-left;

                    @include desktop {
                        align-self: flex-end;
                    }
                }
            }
        }
    }
</style>
