<template>
    <div class="cms-block cms-block-teaser">
        <img
            v-if="src"
            class="cms-block-teaser__img"
            :src="src"
            :alt="block.value.title" />
        <h2
            v-if="block.value.title"
            class="cms-block-teaser__title">
            {{ block.value.title }}
        </h2>
        <p
            v-if="block.value.text"
            class="cms-block-teaser__text">
            {{ block.value.text }}
        </p>
    </div>
</template>

<script>
    import ApiService from "@/services/api.service";
    import CmsBlockMixin from "./CmsBlockMixin";

    export default {
        name: "CmsTeaser",
        mixins: [CmsBlockMixin],
        data() {
            return {
                src: ""
            };
        },
        watch: {
            block() {
                this.getImage();
            }
        },
        mounted() {
            this.getImage();
        },
        methods: {
            getImage() {
                if (!this.block.value.image) this.src = this.block.value.src;
                else {
                    ApiService.get(`/images/${this.block.value.image}`).then(
                        response => (this.src = response.data.meta.download_url)
                    );
                }
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "~@/styles/variables";

    .cms-block-teaser {
        text-align: center;
        margin: 3rem 0;
        padding: 0;

        &__img {
        }

        &__title {
            color: $color-primary;
            font-size: 1.5rem;
            font-weight: 900;
            text-transform: uppercase;
        }

        &__text {
            color: $color-black;
            font-weight: 500;
            font-size: 1.25rem;
            line-height: 1rem;
        }
    }
</style>
