<template>
    <router-link
        class="navigation-item"
        active-class="navigation-item--active"
        :to="to">
        {{ item.title }}
    </router-link>
</template>

<script>
    export default {
        name: "NavigationItem",
        props: {
            item: Object
        },
        computed: {
            to() {
                return {
                    name: "page",
                    params: { slug: this.item.slug, lang: this.$i18n.locale }
                };
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "~@/styles/variables";

    .navigation-item {
        text-decoration: none;
        color: $color-black;
        padding: 0.75rem 1.5rem;
        width: 100%;
        transition: color 0.2s ease;

        &--active,
        &:hover {
            color: $color-primary;
        }

        &--active {
            font-weight: 700;
        }

        &:active {
            color: $color-primary-dark;
        }
    }
</style>
