<template>
    <div
        class="cms-block cms-block--typography cms-block-text"
        :class="block.value.content_align.toLowerCase()">
        <p v-html="block.value.content" />
    </div>
</template>

<script>
    import CmsBlockMixin from "./CmsBlockMixin";

    export default {
        name: "CmsText",
        mixins: [CmsBlockMixin]
    };
</script>

<style scoped lang="scss">
    @import "~@/styles/variables";

    .cms-block-text {
        color: $color-black;
        margin: 0.625rem 0;

        ::v-deep {
            p {
                color: $color-black;
            }
        }

        &.center {
            text-align: center;

            *:has(ul, ol) {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }

        &.left {
            text-align: left;
        }
    }
</style>
