<template>
    <a
        class="lions-club-imprint"
        href="https://magglingen.lionsclub.ch/"
        rel="noopener"
        target="_blank">
        <figure class="lions-club-logo">
            <img
                class="lions-club-logo-img"
                src="../../../assets/logo-lions-club.svg"
                alt="Lions Club Logo" />
        </figure>
    </a>
</template>

<script>
    export default {
        name: "TheLionsClubImprint"
    };
</script>

<style scoped lang="scss">
    .lions-club-imprint {
        width: 100%;

        .lions-club-logo {
            width: 100%;

            .lions-club-logo-img {
                width: 100%;
            }
        }
    }
</style>
