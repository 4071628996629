import Vuex from "vuex";
import Vue from "vue";

import auth from "@/store/auth";
import navigation from "@/store/navigation";
import intent from "@/store/intent";
import routing from "@/store/routing";
import seo from "@/store/seo";
import messages from "@/store/messages";
import settings from "@/store/settings";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    getters: {},
    modules: {
        auth,
        navigation,
        intent,
        routing,
        seo,
        messages,
        settings
    }
});
