<template>
    <div
        id="app"
        :class="classObject">
        <TheHeader />
        <MessageManager />
        <router-view v-show="!isLoading" />
        <div
            v-show="isLoading"
            class="loading">
            <i
                class="icon loader"
                aria-hidden="true" />
        </div>
        <TheFooter />
    </div>
</template>

<script>
    import "normalize.css";
    import TheHeader from "@/components/layout/header/TheHeader.vue";
    import TheFooter from "@/components/layout/footer/TheFooter.vue";
    import MessageManager from "@/components/MessageManager.vue";
    import ApiService from "@/services/api.service";
    import Cookies from "js-cookie";

    export default {
        components: {
            MessageManager,
            TheFooter,
            TheHeader
        },
        computed: {
            language() {
                return this.$store.state.auth.language;
            },
            title() {
                return this.$store.state.seo.title;
            },
            description() {
                return this.$store.state.seo.description;
            },
            isLoading() {
                return this.$store.state.auth.loading;
            },
            navigationOpen() {
                return this.$store.state.navigation.open;
            },
            classObject() {
                return {
                    "navigation-open": this.navigationOpen
                };
            },
            settings() {
                return this.$store.state.settings.settings;
            },
            auth() {
                return {
                    messages() {
                        return {
                            successMessage: null,
                            errorMessage: this.$t(
                                "Your access and refresh tokens are expired, please login again."
                            ),
                            refreshSuccessMessage: this.$t(
                                "Your access token has been refreshed successfully."
                            ),
                            refreshErrorMessage: this.$t(
                                "Your access token could not been refreshed, please login again. "
                            )
                        };
                    }
                };
            }
        },
        watch: {
            language(lang) {
                this.setHtmlLang(lang);
            },
            title(title) {
                this.setHtmlTitle(title);
            },
            description(description) {
                this.setHtmlDescription(description);
            },
            navigationOpen(open) {
                this.setScrollLock(open);
            }
        },
        mounted() {
            this.getCookies();
            const timeout = 3600000; // 60 minutes
            this.checkAuthInterval = setInterval(this.checkAuth, timeout);
            this.setHtmlLang(this.language);
            this.setHtmlTitle(this.title);
            this.setHtmlDescription(this.description);
            this.getSettings();
            this.getPhase();
        },
        beforeDestroy() {
            clearInterval(this.checkAuthInterval);
        },
        methods: {
            getCookies() {
                const language = Cookies.get("language");
                const access = Cookies.get("access");
                const refresh = Cookies.get("refresh");
                const user = Cookies.get("user")
                    ? JSON.parse(Cookies.get("user"))
                    : {};
                if (language) {
                    this.$store.commit("auth/setLanguage", language);
                }
                if (access && refresh && user.id) {
                    this.$store.commit("auth/setTokens", { access, refresh });
                    this.$store.commit("auth/setUser", user);
                    this.checkAuth();
                }
            },
            setHtmlLang(lang) {
                document.documentElement.setAttribute("lang", lang);
            },
            setHtmlTitle(title) {
                document.title = title;
            },
            setHtmlDescription(description) {
                document
                    .querySelector('meta[name="description"]')
                    .setAttribute("content", description);
            },
            setScrollLock(open) {
                document.body.classList.toggle("scroll-lock", open);
            },
            checkAuth() {
                this.$store
                    .dispatch("auth/checkAuth", this.auth.messages)
                    .then(this.checkAuthSuccess)
                    .catch(this.checkAuthError);
            },
            setAuthHeader() {
                if (this.$store.getters["auth/isLoggedIn"])
                    ApiService.enableAuth(this.$store.state.auth.access);
            },
            checkAuthSuccess() {
                this.setAuthHeader();
                this.$store.dispatch("auth/user");
            },
            checkAuthError() {
                this.$store.dispatch("auth/logout");
                this.$store.commit("intent/resetIntent");
                this.$store.commit("intent/resetRunner");
                this.$router.push("login");
            },
            getSettings: function () {
                const settingsUrl = "/runsettings";
                ApiService.get(settingsUrl).then(response => {
                    this.$store.commit("settings/setSettings", response.data);
                });
            },
            getPhase: function () {
                const settingsUrl = "/phase";
                ApiService.get(settingsUrl).then(response => {
                    this.$store.commit(
                        "settings/setPhase",
                        response.data.phase
                    );
                });
            }
        }
    };
</script>

<style lang="scss">
    @import "~@/App";
</style>
