<template>
    <List :elements="filteredElements">
        <template v-slot:item="element">
            <ListItemRanking
                :element="element"
                :show-avatar="showAvatar" />
        </template>
        <template
            v-if="totalRow"
            v-slot:additional>
            <h3>{{ $t("Total") }}</h3>
            <h3 class="list__right">
                {{ totalRow.value }}
            </h3>
        </template>
    </List>
</template>

<script>
    import List from "@/components/lists/List.vue";
    import ListItemRanking from "@/components/lists/item/ListItemRanking.vue";

    export default {
        name: "RankingList",
        components: { List, ListItemRanking },
        props: {
            elements: {
                type: Array,
                default: () => []
            },
            showAvatar: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                totalRow: undefined
            };
        },
        computed: {
            filteredElements() {
                return this.elements
                    .map(row => ({
                        ...row,
                        value: row.value.replace(
                            row.total.toString(),
                            row.total.toLocaleString("de-CH").replace("’", "'")
                        )
                    }))
                    .filter(row => {
                        if (
                            Object.keys(row).includes("total_row") &&
                            row.total_row
                        ) {
                            this.totalRow = row;
                            return false;
                        }
                        return true;
                    });
            }
        }
    };
</script>
