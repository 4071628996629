import Blocks, { getBlockComponent } from "./blocks";
import CmsBlockMixin from "./blocks/CmsBlockMixin";

export default {
    components: Blocks,
    mixins: [CmsBlockMixin],
    methods: {
        getBlockComponent
    }
};
