<template>
    <div class="runner-ticker">
        <Timestamp
            class="runner-ticker__left"
            :timestamp="runner.created_at"
            block />
        <Avatar :runner="runner" />
        <div class="runner-ticker__center">
            <h3>
                {{ runner.runner_firstname }}
                {{ runner.runner_lastname }}
            </h3>
            <Timestamp
                class="runner-ticker__center__time"
                :timestamp="runner.created_at" />
        </div>
        <h3
            v-if="Math.floor(runner.amount_gained * 100) !== 0"
            class="list__right">
            + {{ runner.amount_gained }}
        </h3>
    </div>
</template>

<script>
    import Avatar from "@/components/Avatar.vue";
    import Timestamp from "@/components/Timestamp.vue";

    export default {
        name: "ListItemRunnerTicker",
        components: { Avatar, Timestamp },
        props: {
            runner: {
                type: Object,
                default: () => {}
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "~@/styles/variables";
    @import "~@/styles/mixins";

    .runner-ticker {
        display: flex;
        align-items: center;
        gap: 0.625rem;
        width: 100%;

        &__left {
            display: none;
            text-align: left;
            min-width: 3.5rem;

            @include desktop {
                display: flex;
            }
        }

        &__center {
            display: flex;
            flex-direction: column;
            gap: 0.25rem;

            &__time {
                @include desktop {
                    display: none;
                }
            }
        }
    }
</style>
