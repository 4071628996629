import { render, staticRenderFns } from "./CmsSponsors.vue?vue&type=template&id=fa234fd6&scoped=true"
import script from "./CmsSponsors.vue?vue&type=script&lang=js"
export * from "./CmsSponsors.vue?vue&type=script&lang=js"
import style0 from "./CmsSponsors.vue?vue&type=style&index=0&id=fa234fd6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa234fd6",
  null
  
)

export default component.exports