<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
    import "normalize.css";

    export default {
        data() {
            return {};
        },
        computed: {
            language() {
                return this.$store.state.auth.language;
            },
            title() {
                return this.$store.state.seo.title;
            }
        },
        watch: {
            language(lang) {
                this.setHtmlLang(lang);
            },
            title(title) {
                this.setHtmlTitle(title);
            }
        },
        mounted() {
            this.setHtmlLang(this.language);
            this.setHtmlTitle(this.title);
        },
        methods: {
            setHtmlLang(lang) {
                document.documentElement.setAttribute("lang", lang);
            },
            setHtmlTitle(title) {
                document.title = title;
            }
        }
    };
</script>

<style lang="scss">
    @import "~@/App";
</style>
