/* eslint-disable max-len */
export default {
    "Together": "Ensemble",
    "against rare": "contre les maladies",
    "disease": "rares",
    "Your engagement": "Ton engagement",
    "Run & course": "Course & piste",
    "Organizer": "Organisateur",
    "Sponsors": "Sponsors",
    "Registration deadline by 05. Mai 2020 / 23:59":
        "Date limite d'inscription 04 mai 2021 / 23:59",
    "Wednesday, 06. Mai 2020 / 16:30 - 20:30":
        "Mercredi 5 mai 2021 / 16h30 - 20h30",
    "I'll run": "Je cours",
    "I'll sponsor": "Je sponsorise",
    "+442 meters of height difference from the bottom to the top station of the Biel-Magglingen cable car on the shortest route.":
        "+442 mètres de dénivelé entre la station inférieure et la station supérieure du téléphérique de Bienne-Maccolin sur le trajet le plus court",
    "An exceptional commitment on an exceptional route for an exceptional purpose!":
        "Un engagement exceptionnel sur un itinéraire exceptionnel dans un but exceptionnel !",
    "Register": "Inscris-toi",
    "Who can participate?": "Qui peut participer ?",
    "Sponsoring": "Sponsoring",
    "In favour of": "En faveur de",
    "Everyone who feels fit enough to complete the course one or more times; running, hiking, walking - time is not an issue. The descent is done by train for relaxation.":
        "Toute personne qui se sent suffisamment en forme pour effectuer le parcours une ou plusieurs fois ; course, randonnée, marche - le temps n'est pas un problème. La descente se fait en train pour la détente",
    "Each runner acquires her or his own sponsors. The sponsors can set an amount per vertical meter, per course or a flat rate for participation.":
        "Chaque coureur acquiert ses propres sponsors. Les sponsors peuvent fixer un montant par mètre d'altitude, par parcours ou un forfait pour la participation",
    "The aim of the run is to generate money to fight rare diseases and to support those affected and their families. Rare diseases are diseases that occur only sporadically; in Switzerland, for example, there are often fewer than 20 people affected. In the case of such diseases there is usually a lack of specific medication for treatment and the insurance question is often unresolved, which often leads to personal and financial hardship for the affected and their relatives.":
        "L'objectif de la course est de récolter des fonds pour la lutte contre les maladies rares et pour soutenir les personnes concernées et leurs familles. Les maladies rares sont des maladies qui ne surviennent que de façon sporadique, en Suisse par exemple, souvent moins de 20 personnes sont concernées. Pour de telles maladies, les médicaments spécifiques manque souvent, et la question de l'assurance reste fréquemment non résolue, ce qui met les personnes concernées et leurs famillies dans des situations personnelles et financières difficiles",
    "Event sponsors": "Sponsors de l'événement",
    "Many thanks for your support!": "Merci pour ton soutien!",
    "Runners": "Coureur·euse·s",
    "Registration deadline": "Délai d'inscription",
    "Newsletter": "Newsletter",
    "Subscribe": "S'abonner",
    "Menu": "Menu",
    "My +442": "My +442",
    "Follow us": "Follow us",
    "Made with lots of": "Made with lots of",
    "and": "and",
    "in Biel/Bienne by": "in Biel/Bienne by",
    "Smartfactory GmbH.": "Smartfactory",
    "I run": "Je participe",
    "too": "aussi",
    "Login form": "Formulaire d'inscription",
    "Please enter your e-mail address below to receive your one time password per e-mail.":
        "Veuillez saisir ton adresse e-mail ci-dessous pour recevoir un code d'inscription par e-mail.",
    "E-Mail address": "Adresse e-mail",
    "e-mail@example.com": "e-mail@example.com",
    "Send one time password": "Envoyer le code d'inscription",
    "Thank you. We have just sent you an e-mail.":
        "Merci beaucoup. Ton code d'inscription a été envoyé par e-mail.",
    "Click on the link you have received or enter the registration code below.":
        "Cliquais sur le lien ou entrais le code d'inscription ci-dessous.",
    "error": "Erreur",
    "success": "Succès",
    "warning": "Avertissement",
    "Your access and refresh tokens are expired, please login again.":
        "Ta session a expiré, veux-tu te reconnecter.",
    "Your access token could not been refreshed, please login again. ":
        "Ta session n'a pas pu être renouvelée, veux-tu te reconnecter.",
    "Your access token has been refreshed successfully.":
        "Ta session a été renouvelée avec succès.",
    "Thank you for your login request, you should receive an e-mail with your registration code.":
        "Merci pour ton inscription, tu recevras prochainement un e-mail avec ton code d'inscription.",
    "We sent the e-mail again, you should receive an e-mail with your registration code.":
        "Tu recevras un e-mail avec ton mot de passe.",
    "Something went wrong, please check your information and try again later.":
        "Une erreur est survenue, vérifie tes coordonnées ou réessayer plus tard.",
    "Something went wrong, please try again later.":
        "Une erreur est survenue, réessaie plus tard.",
    "Please update your profile information.":
        "Complète les informations de ton profil.",
    "Save your information": "Enregistrer les informations",
    "Firstname": "Prénom",
    "Lastname": "Nom de famille",
    "Thank you for completing your profile information.":
        "Merci d'avoir complété les informations de ton profil.",
    "Dashboard": "Dashboard",
    "Welcome! Your login was successful.":
        "Bienvenue! Tu t'es inscrit avec succès.",
    "Registration code": "Code d'inscription",
    "Login": "Connexion",
    "Not receiving the e-mail?": "Tu n'as pas reçu d'e-mail?",
    "Click here.": "Renvoyer.",
    "Oh no. This page does not exist.": "Oh non, cette page n'existe pas",
    "Hello {name},": "Bonjour {name} !",
    "Welcome to your 442 board.": "Bienvenue à votre dashboard +442.",
    "Logout": "déconnexion",
    "My run": "Ma course",
    "My sponsoring": "Mon sponsoring",
    "Thank you for completing your runner information.":
        "Merci d'avoir complété tes informations.",
    "Please complete your runner information:": "Complète tes informations :",
    "I confirm that I am fit enough to run.":
        "Je confirme que ma condition physique est assez bonne pour participer à la course et valide mon inscription.",
    "City": "Lieu",
    "Upload your profile image:": "Télécharge ta photo de profil:",
    "Send runner information:": "Actualise tes informations :",
    "Send runner information": "Enregistrer",
    "Search": "Chercher",
    "Sponsor": "Sponsor",
    "show more": "en savoir plus",
    "no runners found": "aucun coureur n'a été trouvé",
    "Your invoicing address": "Ton adresse de facture",
    "You support the following runners:":
        "Tu supportes les coureurs/euses suivants/es:",
    "Looks like you didn't create any sponsoring yet.":
        "Pour l'instant, tu ne parraine aucun/e coureur/euse. Clique sur «nouveau sponsoring» pour créer un nouveau sponsoring.",
    "The sponsorings with a «fixed amount» are only activated when a runner completes their first lap.":
        "Les parrainages avec le mode «montant fixe» ne sont activés qu'une fois que le coureur / la coureuse a effectué le premier tour.",

    "Do you have a problem?": "Y a-t-il un problème?",
    "If you would like to revoke a sponsoring you can write to:":
        "Si tu souhaites supprimer un sponsoring, écris-nous: {email}",
    "Save invoice address": "Enregistrer l'adresse de facture",
    "Please select a runner:": "Sélectionne un/e coureur/euse:",
    "Your sponsoring was added successfully!":
        "Ton sponsoring a été enregistré - Merci!",
    "Your sponsoring could not been added, please try again.":
        "Ton sponsoring n'a pas pu être enregistré. Vérifie tes données et réessayer.",
    "Please choose your sponsoring method and amount in CHF:":
        "Choisis le mode de sponsoring et le montant:",
    "Method": "Mode",
    "Sponsoring Amount in CHF": "Mode de sponsoring en CHF",
    "Save sponsoring": "Enregistre ton sponsoring",
    "You will be sponsoring:": "Tu crées un sponsoring pour:",
    "Phone": "Téléphone",
    "Company": "Entreprise (facultatif)",
    "Street, house number": "Rue, Numéro",
    "ZIP Code": "Code postal",
    "User avatar": "Votre avatar",
    "Looks like you don't have any sponsors yet.":
        "Ici, tu peux vérifier qui te parraine pour ta course. Pour l'instant, tu n'as pas encore de sponsors.",
    "Spread the word and share the below link with your friends:":
        "Tu peux partager ce lien avec tes amis pour les acquérir comme sponsors :",
    "Your sponsors": "Vos sponsors",
    "Edit": "éditer",
    "Please complete by entering your Invoicing details below:":
        "Indique l'adresse à laquelle nous pouvons envoyer la facture après la course:",
    "Your invoice address was saved successfully!":
        "Ton adresse de facturation a été enregistrée!",
    "Your invoice address could not been saved, please try again.":
        "Erreur lors de l'enregistrement. Veux-tu réessayer.",
    "Update runner profile": "Actualiser",
    "Sign up as runner:":
        "Ici, tu peux t'inscrire en tant que coureur. Ton profil sera ensuite affiché dans la liste des coureurs et d'autres utilisateurs pourront te parrainer. Dans la liste des coureurs, seuls ta photo de profil, ton nom et ton lieu de résidence sont affichés. Ton adresse n'est jamais affichée publiquement.",
    "Please edit your runner information:":
        "Ici, tu peux modifier le lieu de séjour qui est affiché dans la liste des coureurs. Note que tu ne peux pas supprimer une inscription en tant que coureur en ligne. Écris à support@442.run si tu dois retirer ton participation en tant que coureur.",
    "signed up as runner": "Statut : inscrit comme coureur",
    "Per meter of altitude": "Par mètre d'altitude",
    "Fixed amount": "Montant fixe",
    "Per round": "Par tour",
    "Homepage": "Homepage",
    "Want to invite someone as a runner?":
        "Tu veux inviter quelqu'un en tant que coureur/coureuse ?",
    "Enter an e-mail message here and we will send an invitation:":
        "Entre une adresse e-mail ici et nous enverrons une invitation.",
    "Invite": "Inviter",
    "Thank you! We send your friend an invitation via E-Mail.":
        "Mercy beaucoup! Nous avons envoyé ton invitation.",
    "We could not send an E-Mail your friend, please try again later.":
        "Nous n'avons pas pu envoyer ton invitation, réessaie plus tard.",
    "We already sent an invitation to this E-Mail address.":
        "Nous avons déjà envoyé une invitation à cette personne.",
    "realtime counter": "Compteur de dons",
    "meters": "Mètres d'altitude",
    "rounds": "Parcours",
    "money collected": "Fonds récoltés",
    "ticker": "Live-Ticker",
    "Support me": "Soutiens-moi",
    "Please enter a number": "Entre un numéro",
    "Run starts at": "La course commence à",
    "Run ends at": "La course se termine à",
    "Event over": "L'événement est terminé",
    "The event is over, but we will be back next year":
        "La course sponsorisée a eu lieu du 5 mai au 2 juin 2021. Grâce à l'engagement de tous les coureurs et sponsors, l'événement a été un succès total ! Nous vous informerons de l'événement 2022 à une date ultérieure.",
    "Thanks to all who took part": "Merci à tous ceux qui ont participé",
    "Run starts in": "La course commence dans",
    "Run ends in": "La course se termine dans",
    "As soon as the first laps have been run, they will be shown here":
        "Dès que les premiers tours auront été effectués, ils seront affichés ici.",
    "TOP": "Station supérieure",
    "MIDDLE": "Station intermédiaire",
    "BOTTOM": "Station de fond",
    "Please enter a number greater than 0":
        "Saisis un nombre plus grand que zéro.",
    "It starts soon": "C'est pour bientôt",
    "to": "au",
    "from": "Du",
    "Entries": "Entrées",
    "QR Scanner": "QR Scanner",
    "To the Scanner": "Ouvrir le scanner de code QR",
    "This QR code is for runners of the 442.run sponsor run to scan their laps. You can get more infos about the run on 442.run.":
        "Ce code QR est utilisé pour la course sponsorisée 442. Vous pouvez trouver plus d'informations sur cette course sur 442.run.",
    "If you are a runner, you need to first log in and then scan the code again":
        "Si tu es un coureur, connecte-tois et rescanne le code QR.",
    "How it works": "Comment ça marche",
    "More Information": "En savoir plus",
    "If you made an error and would like to get a lap removed, please contact us at support@442.run.":
        "Y a-t-il un problème ? Un tour n'a pas été enregistré ou a été enregistré de manière incorrecte ? Il suffit d'écrire à {email} et nous corrigerons l'erreur.",
    "Entry was saved": "L'entrée a été enregistrée",
    "Entry could not be saved, try again or use the buttons below.":
        "Une erreur s'est produite, essaye encore ou enregistre-toi manuellement en appuyant sur l'un des boutons.",
    "Entry could not be saved, please log in and use the scanner you find in the dashboard.":
        "Une erreur s'est produite, utilise le scanner QR du tableau de bord.",
    "You are already checked in here, go back to the start and scan the checkpoint to start a new lap.":
        "T'as déjà terminé ce tour, scanne le code QR à la station en bas pour commencer un nouveau tour.",
    "You are already checked in here, run to the top and scan the code there to finish the lap.":
        "T'as déjà commencé un tour, scanne le code QR à la station supérieure pour terminer le tour en cours.",
    "Scan a QR code to start or end a lap.":
        "Scanne le code QR à la station inférieure ou supérieure du téléphérique de Macolin pour commencer ou terminer un tour. Si t'as des problèmes pour scanner, tu peux également utiliser le bouton pour t'enregistrer manuellement.",
    "Station bottom": "Check-In station inférieure",
    "Station top": "Check-Out station supérieure",
    "No entries found": "Tu n'as pas encore d'entrées",
    "New": "Nouveau",
    "New sponsoring": "Nouveau Sponsoring",
    "Total": "Total",
    "Scanner": "Scanner de code QR",
    "The event has not started yet": "L'événement n'a pas encore commencé",
    "Check-In Instructions":
        "T'es à la station inférieure ou supérieure et tu veux commencer ou terminer une ronde ? Clique sur le bouton ci-dessous et sélectionne \"Autoriser\" si un popup s'ouvre. Ensuite, tu peux scanner le code QR sur le panneau pour le check-in ou check-out.",
    "Check-In Success Message":
        "T'es maintenant enregistré. On te souhaite beaucoup de plaisir ! Et : N'oublie pas de faire le check-out à la station supérieure, pour que ton tour soit compté ;-)",
    "Check-Out Success Message":
        "Félicitations ! Ton tour a été enregistré avec succès. Si tu veux courir un autre tour, tu peux t'enregistrer à nouveau à la station inférieure.",
    "Your run": "T'es prêt ?",
    "The event is over, you can't enter more laps":
        "L'événement est terminé, aucun tour supplémentaire ne peut être ajouté.",
    "You need to check in first":
        "Scanne d'abord le code QR à la station inférieure pour commencer un tour. Si t'as des problèmes pour scanner, tu puex également utiliser le bouton pour t'enregistrer manuellement.",
    "To the dashboard": "retour au dashboard",
    "Running total": "Argent collecté",
    "With your engagement, you have so far gathered the following sponsoring amount":
        "Grâce à ton engagement, t'as jusqu'à présent pu réunir le montant de parrainage suivant: {amount}",
    "CHF {amount} per meter of altitude": "CHF {amount} par mètre d'altitude",
    "CHF {amount} fixed amount": "CHF {amount} montant fixe",
    "CHF {amount} per round": "CHF {amount} par tour",
    "You have finished rounds so far.":
        "Tu n'as pas encore fait de tour. | Tu as fait {count} tour jusqu'à présent. |  Tu as fait {count} tours jusqu'à présent.",
    "Profile": "Profil",
    "Share link": "Partager le lien",
    "Click here to create this group":
        "Rien trouvé...Cliquer ici pour créer ce groupe.",
    "Start typing to search for a group.": "Saisis le nom du groupe à chercher",
    "Are you taking part as a member of a group? Enter the name of the group below:":
        "Participes-tu en tant que membre d'un groupe (par ex. classe d'école, association, entreprise) ? Saisis ci-dessous le nom de ton groupe pour le rejoindre ou le créer:",
    "Group": "Groupe",
    "You are not currently in a group":
        "Tu n'es actuellement pas dans un groupe.",
    "Support / Contact": "Support / Contact",
    "Please update your e-mail": "Veuillez vérifier votre adresse e-mail",
    "An e-mail recently sent to [[e-mail]] has bounced. Here's why: [[reason]]":
        "Notre e-mail adressé à [[e-mail]] n'a pas pu être délivré. Veuillez vérifier l'adresse e-mail que vous avez saisie et la modifier si nécessaire. Message d'erreur : [[reason]]",
    "An e-mail recently sent to [[e-mail]] has bounced.":
        "Notre e-mail adressé à [[e-mail]] n'a pas pu être délivré.",
    "Try with a different e-mail": "Essaie avec une autre adresse e-mail",
    "No file chosen": "Aucun fichier sélectionné",
    "Choose a file": "Choisis un fichier",
    "This field is required": "Ce champ est obligatoire.",
    "This does not look like a valid e-mail":
        "Cela ne ressemble pas à une adresse e-mail valide.",
    "Please wait, data is loading...":
        "Merci de patienter, les données sont en cours de chargement...",
    "Thanks for subscribing!": "Merci beaucoup pour ton inscription !"
};
