<template>
    <div class="runner-list-search">
        <div
            v-if="!runnerId"
            class="search">
            <input
                id="search"
                v-model="searchTerm"
                :placeholder="$t('Search')"
                @keypress="clearRunner"
                @click="clearRunner" />
            <div
                class="icon close-sm"
                @click="clearSearch" />
        </div>

        <transition-group
            is="transition-group"
            id="runner-result-list-wrapper"
            class="runner-result-list-wrapper"
            tag="div"
            name="fade-list">
            <List
                key="runner-result-list"
                :elements="innerRunners"
                :show-separators="!runnerId"
                :wrap="true"
                class="runner-result-list">
                <template v-slot:item="runner">
                    <ListItemRunnerSponsor :runner="runner" />
                </template>
            </List>
        </transition-group>
        <div
            v-if="runners.length <= 0"
            id="runner-link__no-runner">
            {{ $t("no runners found") }}
        </div>
        <button
            v-if="isShowMoreButtonVisible"
            class="runner-list-search__show-more button button--secondary"
            @click="showMore">
            {{ $t("show more") }}
        </button>
    </div>
</template>

<script>
    import { INTENTS } from "@/store/intent";
    import { PHASES } from "@/constants.js";
    import List from "./List.vue";
    import ListItemRunnerSponsor from "./item/ListItemRunnerSponsor.vue";

    export default {
        name: "RunnerSearch",
        components: {
            List,
            ListItemRunnerSponsor
        },
        props: {
            runners: {
                type: Array,
                default: () => []
            },
            searchGroup: {
                type: Boolean,
                default: true
            }
        },
        mounted() {
            this.updateShuflledRunners();
        },
        data() {
            return {
                shuffledRunners: [],
                innerRunners: [],
                itemShowCount: 5,
                searchTerm: "",
                runnerId: this.$route.query.runner
            };
        },
        computed: {
            phase() {
                return this.$store.state.settings.phase;
            },
            phaseIsAfterRun() {
                return this.phase === PHASES.AFTER_RUN;
            },
            isShowMoreButtonVisible() {
                return (
                    this.itemShowCount < this.runners.length && !this.runnerId
                );
            }
        },
        watch: {
            runners() {
                this.updateShuflledRunners();
            },
            shuffledRunners() {
                this.updateInnerRunners();
            },
            itemShowCount() {
                this.updateInnerRunners();
            },
            searchTerm() {
                this.updateInnerRunners();
            }
        },
        methods: {
            updateShuflledRunners() {
                this.shuffledRunners = this.shuffle(this.runners);
            },
            updateInnerRunners() {
                if (this.shuffledRunners.length <= 0)
                    return (this.innerRunners = []);

                if (this.runnerId) {
                    this.innerRunners = this.shuffledRunners.filter(
                        runner => runner.id === parseInt(this.runnerId)
                    );
                    return;
                }

                const searchTerm = this.searchTerm.toLowerCase();

                this.innerRunners = this.shuffledRunners
                    .filter(runner => {
                        return (
                            runner.first_name
                                .toLowerCase()
                                .includes(searchTerm) ||
                            runner.last_name
                                .toLowerCase()
                                .includes(searchTerm) ||
                            runner.city.toLowerCase().includes(searchTerm) ||
                            runner.id === parseInt(searchTerm.trim()) ||
                            (this.searchGroup &&
                                (runner.group_name || "")
                                    .toLowerCase()
                                    .includes(searchTerm))
                        );
                    })
                    .slice(0, this.itemShowCount);
            },

            shuffle(array) {
                let currentIndex = array.length,
                    temporaryValue,
                    randomIndex;
                while (0 !== currentIndex) {
                    randomIndex = Math.floor(Math.random() * currentIndex);
                    currentIndex -= 1;
                    temporaryValue = array[currentIndex];
                    array[currentIndex] = array[randomIndex];
                    array[randomIndex] = temporaryValue;
                }
                return array;
            },
            showMore() {
                this.itemShowCount += 10;
            },
            clearRunner() {
                this.runnerId = null;
            },
            clearSearch() {
                this.searchTerm = "";
            },
            getSponsorLink(runner) {
                return {
                    name: this.isLoggedIn ? "add_sponsoring" : "login",
                    params: {
                        lang: this.$i18n.locale
                    },
                    query: {
                        intent: INTENTS.SPONSOR.toLowerCase(),
                        runner: runner.id
                    }
                };
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "~@/styles/variables";
    @import "~@/styles/mixins";

    .runner-list-search {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        @include desktop {
            > * {
                width: 588px;
            }
        }

        &__no-runner {
            @include font("text");
            text-align: center;
        }

        &__show-more {
            margin-top: 2.5rem;
            width: auto;
        }
    }

    .fade-list-move {
        transition: transform 0.25s ease;
    }

    .fade-list-leave-to {
        transition: all 0.5s ease;
        opacity: 0;
    }

    .runner-result-list-wrapper {
        width: 100%;
    }
</style>
