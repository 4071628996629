<template>
    <div
        class="cms-block"
        :class="['multi-col-grid', block.value.layout]">
        <div
            v-for="column in block.value.columns"
            :key="column.id"
            class="cms-column"
            :class="column.id">
            <div v-for="block in column">
                <component
                    :is="getBlockComponent(block)"
                    :block="block" />
            </div>
        </div>
    </div>
</template>

<script>
    import CmsParentBlockMixin from "./CmsParentBlockMixin";

    export default {
        name: "CmsMultiCol",
        mixins: [CmsParentBlockMixin]
    };
</script>

<style scoped lang="scss">
    @import "~@/styles/mixins";

    .multi-col-grid {
        display: grid;
        gap: 1rem;
        grid-template-columns: 1fr;

        @include desktop {
            gap: 1.5rem;
        }

        &::v-deep {
            .cms-block {
                padding: 0;
            }

            .cms-block-heading h2 {
                margin-bottom: 0.625rem;
            }
        }

        * {
            max-width: 100%;
        }
    }

    @include desktop {
        .multi-col-option-1 {
            grid-template-columns: 1fr 2fr;
        }

        .multi-col-option-2 {
            grid-template-columns: 2fr 1fr;
        }

        .multi-col-option-3 {
            grid-template-columns: 1fr 1fr;
        }

        .multi-col-option-4 {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
</style>
