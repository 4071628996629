<template>
    <section class="cms-block cms-block-sponsors">
        <a
            v-for="sponsor in sponsors"
            :key="sponsor.company"
            class="cms-block-sponsors__sponsor"
            :href="sponsor.link"
            target="_blank">
            <img
                class="cms-block-sponsors__sponsor__image"
                :src="sponsor.logo"
                :alt="sponsor.company"
                :title="sponsor.company" />
        </a>
    </section>
</template>

<script>
    import ApiService from "@/services/api.service";
    import CmsBlockMixin from "./CmsBlockMixin";

    export default {
        name: "CmsSponsors",
        mixins: [CmsBlockMixin],
        data() {
            return {
                sponsors: []
            };
        },
        mounted() {
            this.fetchSponsors();
        },
        methods: {
            fetchSponsors() {
                ApiService.get("event_sponsor").then(response => {
                    this.sponsors = response.data.sort(
                        (a, b) => a.order > b.order
                    );
                });
            }
        },
        watch: {
            $route(to, from) {
                if (to.path !== from.path) {
                    this.fetchSponsors();
                }
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "~@/styles/variables";

    .cms-block-sponsors {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 2rem;
        row-gap: 4rem;
        align-items: center;

        &__sponsor {
            display: block;

            &__image {
                max-width: 100%;
            }
        }
    }
</style>
