const actionsObj = {
    setTitle: function ({ commit }, title) {
        commit("setTitle", title);
    },
    setDescription: function ({ commit }, description) {
        commit("setDescription", description);
    }
};

const titlePrefix = "442.run";

const stateObj = {
    title: titlePrefix,
    description: ""
};

const mutationsObj = {
    setTitle(state, title) {
        state.title = titlePrefix;

        if (title) state.title += " - " + title;
    },
    setDescription(state, description) {
        state.description = description;
    }
};

const gettersObj = {};

export default {
    namespaced: true,
    actions: actionsObj,
    state: stateObj,
    mutations: mutationsObj,
    getters: gettersObj
};
