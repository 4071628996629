<template>
    <a
        class="banner"
        :href="url">
        {{ text }}
    </a>
</template>

<script>
    export default {
        name: "Banner",
        props: {
            text: {
                type: String,
                default: ""
            },
            url: {
                type: String,
                default: null
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "~@/styles/variables";
    @import "~@/styles/mixins";

    .banner {
        width: 100%;
        height: 100%;
        background-color: $color-primary;
        padding: 0.625rem 2.5rem;
        text-align: center;
        color: $color-white;

        @include font("text-upper");
        font-weight: normal;
        text-decoration: none;
    }
</style>
