<template>
    <div class="cms-block cms-runner-list">
        <RunnerSearch
            :runners="runners"
            :search-group="false" />
    </div>
</template>

<script>
    import ApiService from "@/services/api.service";
    import CmsBlockMixin from "./CmsBlockMixin";
    import RunnerSearch from "@/components/lists/RunnerSearch.vue";

    export default {
        name: "CmsGroupRunnerList",
        components: { RunnerSearch },
        mixins: [CmsBlockMixin],
        data() {
            return {
                runners: []
            };
        },
        computed: {
            group() {
                return this.block.value.group
                    ? this.block.value.group.group
                    : 0;
            }
        },
        mounted() {
            if (this.group <= 0) return;
            this.$store.commit("auth/setLoading", true);
            ApiService.get(`/runner?group=${this.group}`)
                .then(response => (this.runners = response.data))
                .finally(() => this.$store.commit("auth/setLoading", false));
        }
    };
</script>
