export const parseJwt = token => {
    try {
        return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
        return null;
    }
};

export const uuidv4 = () =>
    ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        // eslint-disable-next-line no-mixed-operators
        (
            c ^
            (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
    );

export const formatCurrency = amount => {
    let _amount = String(amount);
    while (_amount.length < 3) _amount = "0" + _amount;
    const toFloatRegex = /^([0-9]*?)([0-9]{1,2})$/g;
    const float = _amount.replace(toFloatRegex, "$1.$2");
    return float;
};

export function getSponsoringText(sponsoring) {
    switch (sponsoring.method) {
        case 1:
            return this.$t("CHF {amount} fixed amount", {
                amount: formatCurrency(sponsoring.amount)
            });
        case 2:
            return this.$t("CHF {amount} per round", {
                amount: formatCurrency(sponsoring.amount)
            });
        default:
            return this.$t("CHF {amount} per meter of altitude", {
                amount: formatCurrency(sponsoring.amount)
            });
    }
}
