<template>
    <nav class="language-switcher">
        <ol>
            <li
                v-for="(lang, index) in languages"
                :key="lang.value">
                <router-link :to="getTranslatedRoute(lang.value)">
                    {{ lang.value }}
                </router-link>
                <span v-if="index < languages.length - 1">|</span>
            </li>
        </ol>
    </nav>
</template>

<script>
    import { locales } from "@/locales";

    export default {
        name: "TheLanguageSwitcher",
        data() {
            return {
                languages: locales
            };
        },
        methods: {
            getTranslatedRoute(lang) {
                let basePath = this.$route.path.split("?")[0];
                basePath = basePath.replace(/^\/[a-z]{2}/, "");

                const translatedRoute = {
                    path: `/${lang}${basePath}`,
                    query: this.$route.query,
                    params: {
                        ...this.$route.params,
                        lang: lang
                    },
                    hash: this.$route.hash
                };

                return translatedRoute;
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "~@/styles/variables";

    .language-switcher {
        display: flex;

        ol {
            margin: 0;
            padding: 0;
            display: flex;
            text-transform: uppercase;
            font-size: 0.875rem;

            li {
                list-style: none;

                a {
                    text-decoration: none;
                    padding: 7px;
                    cursor: pointer;
                    color: $color-black;

                    &:hover,
                    &:focus,
                    &:active,
                    &.router-link-exact-active,
                    &.router-link-active {
                        color: $color-primary;
                        font-weight: 700;
                    }
                }
            }
        }
    }
</style>
