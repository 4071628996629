<template>
    <div class="cms-block cms-block-video">
        <video controls>
            <source
                :src="block.value.src"
                type="video/mp4" />
            {{ $t("Your browser does not support the video tag.") }}
        </video>
    </div>
</template>

<script>
    import CmsBlockMixin from "./CmsBlockMixin";

    export default {
        name: "CmsVideo",
        mixins: [CmsBlockMixin]
    };
</script>

<style scoped lang="scss">
    @import "~@/styles/mixins";
    .cms-block-video {
        display: flex;
        justify-content: center;

        video {
            width: 100%;

            @include desktop {
                max-width: 588px;
            }
        }
    }
</style>
