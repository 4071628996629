<template>
    <div
        class="cms-block"
        :style="{
            height: block.value.space + 'px'
        }" />
</template>

<script>
    import CmsBlockMixin from "./CmsBlockMixin";

    export default {
        name: "CmsSpace",
        mixins: [CmsBlockMixin]
    };
</script>
